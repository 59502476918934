import React from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'components';
import { useService } from 'hooks';
import { dateToString } from 'utils/date';

import ViewFeedback from '../ViewFeedback';
import * as S from './styled';

const Feedbacks = React.forwardRef(({}, ref) => {
  const params = useParams();
  const [currentFeedback, setFeedback] = React.useState();

  const listService = useService('get', `mentorship/${params.mentorship}/feedback`);

  React.useImperativeHandle(ref, () => ({ refresh: listService.fetch }), []);

  const logo = 'https://cdn1.iconfinder.com/data/icons/developer-set-2/512/users-512.png';
  const avatar =
    'https://w7.pngwing.com/pngs/1008/377/png-transparent-computer-icons-avatar-user-profile-avatar-heroes-black-hair-computer.png';

  const data = listService?.data?.data?.feedbacks?.items || [];

  return (
    <S.Container>
      <S.Header>
        <S.Title>Últimas pendências</S.Title>
      </S.Header>

      <Loader show={listService.loading}>
        <S.Content>
          <S.TableHeader container>
            <S.TableTitle size={{ md: 6 }}>Startup</S.TableTitle>
            <S.TableTitle size={{ md: 2 }}>Data</S.TableTitle>
            <S.TableTitle size={{ md: 2 }}>Hora</S.TableTitle>
            <S.TableTitle size={{ md: 2 }}>Mentor</S.TableTitle>
          </S.TableHeader>

          {data.length ? null : <S.Empty>Nenhuma pendência</S.Empty>}

          {data.map((item) => (
            <S.TableRow key={item._id} container onClick={() => setFeedback(item._id)}>
              <S.TableColumn size={{ md: 6 }}>
                <S.StartupData>
                  <S.StartupLogo src={logo} />
                  <S.StartupName>{item.advisor.name}</S.StartupName>
                </S.StartupData>
              </S.TableColumn>

              <S.TableColumn size={{ md: 2 }}>
                <S.ColumnValue>{dateToString(item.createdAt)}</S.ColumnValue>
              </S.TableColumn>

              <S.TableColumn size={{ md: 2 }}>
                <S.ColumnValue>{dateToString(item.createdAt, 'HH:mm')}</S.ColumnValue>
              </S.TableColumn>

              <S.TableColumn size={{ md: 2 }}>
                <S.AdvisorLogo src={avatar} />
              </S.TableColumn>
            </S.TableRow>
          ))}
        </S.Content>
      </Loader>

      {!currentFeedback ? null : <ViewFeedback feedback={currentFeedback} onClose={() => setFeedback(undefined)} />}
    </S.Container>
  );
});

export default Feedbacks;
