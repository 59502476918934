import styled from 'styled-components';

export const Logo = styled.img`
  height: 80px;
  width: auto;
  object-fit: contain;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  box-shadow: 2px 2px 10px 1px #0004;
  padding: 24px;
`;
