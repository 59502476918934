import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Integrations as IntegrationsPage } from 'pages/common';
import { Body } from 'components';

const Integrations: React.FC = () => {
  return (
    <Routes>
      {/* /startup/integrations */}
      <Route
        path="/"
        element={
          <Body>
            <IntegrationsPage type="STARTUP" />
          </Body>
        }
      />
    </Routes>
  );
};

export default Integrations;
