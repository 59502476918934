import styled, { DefaultTheme } from 'styled-components';

export const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
    input: {
      color: '#777',
      border: theme.palette.primary.main,
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
    input: {
      color: '#666666',
      border: theme.palette.primary.main,
    },
  },
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ mode, modes }) => modes[mode].label.color};
`;

export const InputContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const Tooltip = styled.div`
  padding: 8px;
`;

export const TooltipIcon = styled.span``;
