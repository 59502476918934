import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const NewSchedule = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const SubHeader = styled.div`
  font-size: 14px;
  color: #bbb;
  margin-top: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Empty = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #bbb;
  margin-top: 8px;
`;

export const Times = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Time = styled.span<{ active?: boolean }>`
  font-size: 16px;
  color: ${({ theme, active }) => (active ? theme.palette.colors.lightPurple.main : '#bbb')};
  cursor: pointer;
`;

export const Schedule = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background: #f3f3f3;
  border-left: 4px solid ${({ theme }) => theme.palette.colors.lightPurple.main};
  margin-left: 12px;
  padding: 24px;
`;

export const ScheduleTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.colors.lightPurple.main};
`;

export const SchedulePeople = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

export const SchedulePerson = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  font-size: 12px;
  font-weight: bold;
`;
