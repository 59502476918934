import React from 'react';

import { Modal } from 'components';

import * as S from './styled';

interface ChallengeDetailsProps {
  challenge?: {
    link: string;
  };
  onClose: () => void;
}

const ChallengeDetails: React.FC<ChallengeDetailsProps> = ({ challenge, onClose }) => {
  return (
    <Modal show={!!challenge} size={{ md: 8 }} onClose={onClose}>
      <S.IFrame src={challenge?.link} />
    </Modal>
  );
};

export default ChallengeDetails;
