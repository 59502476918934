import React from 'react';

import { Body, Header } from 'components';

import Classes from './components/Classes';
import * as S from './styled';

const ClassesPage: React.FC = () => {
  return (
    <div>
      <Header title="Startup Start" />

      <Body>
        <S.Banner />

        <Classes />
      </Body>
    </div>
  );
};

export default ClassesPage;
