import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import query from 'query-string';
import fileDownload from 'js-file-download';

import { Body, TableList, Header, FilterButton, Button } from 'components';
import { useService } from 'hooks';
import { dateToString, newDbDate } from 'utils/date';
import { getFilters } from 'utils/filters';

import Filters from './filters';

const PAGE_KEY = 'startup_page';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const parsed = query.parse(searchParams.toString());

  const currentPage = parsed?.[PAGE_KEY] || 1;

  const listService = useService('get', '/startup', { page: currentPage, filters: getFilters(parsed.filters) }, true, [
    currentPage,
    parsed.filters,
  ]);

  const downloadService = useService('get', '/startup', {}, false, [parsed.filters]);

  const handleOnDownload = async () => {
    const response = await downloadService.fetch({ file: 'csv', filters: getFilters(parsed.filters) }, undefined, {
      responseType: 'blob',
    });

    fileDownload(response, `startups-${dateToString(newDbDate())}.csv`);
  };

  const handleOnSelect = (row: any) => {
    navigate({ pathname: `${location.pathname}/${row._id}`, search: searchParams.toString() });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ ...parsed, [PAGE_KEY]: page });
  };

  const tableListConfig = [
    {
      title: 'Nome',
      size: {
        md: 5,
      },
      path: 'name',
    },
    {
      title: 'Responsável',
      size: {
        md: 3,
      },
      path: 'responsible.name',
    },
    {
      title: 'Fundação',
      size: {
        md: 2,
      },
      path: (row: any) => dateToString(row.foundedDate),
    },
    {
      title: 'Data de Cadastro',
      size: {
        md: 2,
      },
      path: (row: any) => dateToString(row.createdAt),
    },
  ];

  return (
    <Body>
      <Header
        title="Startups"
        right={
          <>
            <Button variant="outlined" onClick={handleOnDownload} loading={downloadService.loading}>
              Exportar
            </Button>
            <FilterButton Filter={Filters} clearOnChange={[PAGE_KEY]} />
          </>
        }
      />

      <TableList
        onRowClick={handleOnSelect}
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        dataPath="startups.items"
        paginate
        paginatePath="startups"
        onChangePage={handleOnChangePage}
      />
    </Body>
  );
};

export default List;
