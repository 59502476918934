import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 300px;
  height: 100%;
  border-right: 5px solid #000;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    0deg,
    rgba(228, 136, 252, 1) 0%,
    rgba(56, 173, 253, 0.7) 50%,
    rgba(53, 56, 251, 1) 100%
  );
`;

export const Logo = styled.span`
  display: flex;
  width: 38px;
  height: 41px;
`;

export const StartupContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  padding-left: 4px;
`;

export const StartupAvatar = styled.span`
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0px 10px 10px #00000022;
`;

export const StartupDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  flex: 1;
  justify-content: space-around;
`;

export const StartupName = styled.span`
  font-size: 18px;
  line-height: 22px;
`;

export const StartupDate = styled.span`
  font-size: 12px;
  line-height: 15px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const Option = styled(Link)<{ noBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 24px 0px;
  align-items: center;
  transition: ease 0.4s;
  ${({ noBorder }) => (noBorder ? '' : 'border-bottom: 1px solid #ddd')};

  &:hover,
  &:active {
    ${({ noBorder }) => (noBorder ? '' : 'border-bottom: 1px solid #777')};
  }

  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.dark};
  }
`;

export const OptionIcon = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 24px;
`;

export const OptionLabel = styled.span`
  font-size: 14px;
  line-height: 15px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

export const RequestInvestment = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43px;
  background: ${({ theme }) => theme.palette.colors.blue.main};
  border-radius: 0 14px 14px 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 10px 10px #00000033;
`;

export const RequestInvestmentLabel = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.text.light};
`;

export const RequestInvestmentIcon = styled.span`
  width: 15px;
  height: 15px;
  margin-left: 12px;
`;
