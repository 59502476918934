import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Loader, Icon } from 'components';
import { useService } from 'hooks';
import { dateToString } from 'utils/date';

import NewUpload from '../NewUpload';
import * as S from './styled';

const Uploads = () => {
  const params = useParams();
  const [showAddUpload, setAddUpload] = React.useState(false);

  const listService = useService('get', `mentorship/${params.mentorship}/upload`);

  const handleOnCloseAddUpload = (refresh?: boolean) => {
    if (refresh) listService.fetch();

    setAddUpload(false);
  };

  const handleOnDownload = async ({ path, name }: { path: string; name: string }) => {
    const image = await fetch(path);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const anchor = document.createElement('a');
    anchor.href = imageURL;
    anchor.download = name;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(imageURL);
  };

  const data = listService?.data?.data?.uploads?.items || [];

  return (
    <S.Container>
      <S.Header>
        <S.Title>Uploads</S.Title>

        <S.NewUpload onClick={() => setAddUpload(true)}>Novo upload</S.NewUpload>
      </S.Header>

      <Loader show={listService.loading}>
        <S.Content>
          <S.TableHeader container>
            <S.TableTitle size={{ md: 2 }}>Data</S.TableTitle>
            <S.TableTitle size={{ md: 9 }}>Descrição</S.TableTitle>
            <S.TableTitle size={{ md: 1 }}>{''}</S.TableTitle>
          </S.TableHeader>

          {data.length ? null : <S.Empty>Nenhum upload</S.Empty>}

          {data.map((item) => (
            <S.TableRow key={item._id} container>
              <S.TableColumn size={{ md: 2 }}>
                <S.ColumnValue>{dateToString(item.createdAt)}</S.ColumnValue>
              </S.TableColumn>

              <S.TableColumn size={{ md: 9 }}>
                <S.ColumnValue>{item.description}</S.ColumnValue>
              </S.TableColumn>

              <S.TableColumn size={{ md: 1 }} align="flex-end" direction='row'>
                <Icon name="download" onClick={() => handleOnDownload(item)} />
              </S.TableColumn>
            </S.TableRow>
          ))}
        </S.Content>
      </Loader>

      {!showAddUpload ? null : <NewUpload onClose={handleOnCloseAddUpload} />}
    </S.Container>
  );
};

export default Uploads;
