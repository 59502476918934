import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import LogoPNG from 'assets/images/white-logo.png';
import { TextField, Button, FormStep, FormStepConfig, Space, SelectField, TermField } from 'components';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';
import validate from 'utils/validate';

import * as S from './styled';

const validations = {
  'startup.name': [validate.isEmpty()],
  'startup.foundationYear': [validate.isEmpty()],
  'startup.website': [validate.isEmpty()],
  'startup.city': [validate.isEmpty()],
  'startup.state': [validate.isEmptySelect()],
  'startup.responsible.name': [validate.isEmpty(), validate.isName()],
  'startup.responsible.linkedin': [validate.isEmpty()],
  'startup.responsible.email': [validate.isEmpty(), validate.isEmail()],
  'startup.responsible.phone': [validate.isEmpty(), validate.isCellphone()],
  'startup.stage': [validate.isEmptySelect()],
  'startup.activity': [validate.isEmptySelect()],
  'user.name': [validate.isEmpty(), validate.isName()],
  'user.email': [validate.isEmpty(), validate.isEmail()],
  'user.password': [validate.isEmpty()],
  'user.rePassword': [validate.isEmpty(), validate.isEqual('user.password', 'Senhas divergentes')],
  privacyTerm: [validate.isChecked('Você deve aceitar os termos de privacidade')],
  useTerm: [validate.isChecked('Você deve aceitar os termos de uso')],
};

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { setAuth } = AuthContext.useAuth();

  const registerService = useService('post', '/startup/register', {}, false);

  const stateInfo = useService('get', '/info/STATE', { limit: 100 });
  const stageInfo = useService('get', '/info/STAGE', { limit: 100 });
  const activityInfo = useService('get', '/info/ACTIVITY', { limit: 100 });

  const [form, onChange] = useForm({
    validations,
  });

  const handleOnRegister = async () => {
    const data = {
      startup: {
        name: form.getValue('startup.name'),
        foundationYear: form.getValue('startup.foundationYear'),
        website: form.getValue('startup.website'),
        city: form.getValue('startup.city'),
        state: form.getValue('startup.state')?._id,
        responsible: {
          name: form.getValue('startup.responsible.name'),
          linkedin: form.getValue('startup.responsible.linkedin'),
          email: form.getValue('startup.responsible.email'),
          phone: form.getValue('startup.responsible.phone'),
        },
        stage: form.getValue('startup.stage')?._id,
        activity: form.getValue('startup.activity')?._id,
        privacyTerm: form.getValue('privacyTerm'),
        useTerm: form.getValue('useTerm'),
      },
      user: {
        name: form.getValue('user.name'),
        email: form.getValue('user.email'),
        password: form.getValue('user.password'),
      },
    };

    const response = await registerService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Usuário criado com sucesso');
      setAuth({ token: response.data.token, isLogged: true });

      return navigate('/startup');
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao criar usuário', { type: 'error' });
  };

  const formConfig: FormStepConfig = [
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.name',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o nome da Startup?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.foundationYear',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e seu ano de fundação?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            maskConfig: {
              integerLimit: 4,
            },
            mask: 'numeric',
          }),
        },
        {
          schema: 'startup.website',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o site?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.city',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Em qual cidade estão sediados?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.state',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'e o estado?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: stateInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.responsible.name',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o nome do CEO?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.responsible.linkedin',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o Linkedin dele?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.responsible.email',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o e-mail?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },

        {
          schema: 'startup.responsible.phone',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o número do Whatsapp dele?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'phone',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.stage',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'Qual estágio da startup?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: stageInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.activity',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'e o setor de atuação??',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: activityInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'user.name',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual seu nome',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'user.email',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual seu e-mail para acesso',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'user.password',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Insira uma senha',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            type: 'password',
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'user.rePassword',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Repita a senha',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            type: 'password',
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'privacyTerm',
          size: { md: 12 },
          type: TermField,
          props: (schema) => ({
            prefix: 'Aceito os',
            label: 'termos de privacidade',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            url: 'http://orb.tz/politica-de-privacidade',
            mode: 'dark',
          }),
        },
        {
          schema: 'useTerm',
          size: { md: 12 },
          type: TermField,
          props: (schema) => ({
            prefix: 'Aceito os',
            label: 'termos de uso',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            url: 'http://orb.tz/termo-de-uso',
            mode: 'dark',
          }),
        },
      ],
    },
  ];

  return (
    <>
      <S.Logo src={LogoPNG} alt="Logo" />

      <S.Container>
        <S.Content>
          <FormStep
            config={formConfig}
            onSubmit={form.trySave(handleOnRegister)}
            loading={registerService.loading}
            errors={form.errors}
            showErrors={form.setTouched}
          />
        </S.Content>

        <Space top={24} />

        <Button variant="transparent" color="white" width="100%" to="/">
          Já possui conta?
        </Button>
      </S.Container>
    </>
  );
};

export default Register;
