import React from 'react';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import * as R from 'ramda';

import { Body, TableList, Header, Button } from 'components';
import { useService } from 'hooks';

const PAGE_KEY = 'startup_page';

const List: React.FC = () => {
  const location = useLocation();
  const params = useParams<{ challenge?: string }>();
  const LIST_CHALLENGES = R.dropLast(2, location.pathname.split('/')).join('/');

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/challenge/${params.challenge}/startup`, { page: currentPage }, true, [
    currentPage,
  ]);

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListConfig = [
    {
      title: 'Nome',
      size: {
        md: 3,
      },
      path: 'name',
    },
    {
      title: 'Responsável',
      size: {
        md: 4,
      },
      path: 'responsible.name',
    },
    {
      title: 'Email',
      size: {
        md: 3,
      },
      path: 'responsible.email',
    },
    {
      title: 'Telefone',
      size: {
        md: 2,
      },
      path: 'responsible.phone',
    },
  ];

  return (
    <Body>
      <Header
        title="Desafios - Startups"
        left={
          <Button variant="outlined" to={`${LIST_CHALLENGES}?${searchParams.toString()}`}>
            Voltar
          </Button>
        }
      />

      <TableList
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        dataPath="startups.items"
        paginate
        paginatePath="startups"
        onChangePage={handleOnChangePage}
      />
    </Body>
  );
};

export default List;
