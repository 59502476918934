import styled from 'styled-components';

const getMode = () => ({
  light: { label: { color: '#fff' } },
  dark: { label: { color: '#666' } },
});
export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  /* max-width: 800px; */
  width: 100%;
  flex-direction: column;
`;
