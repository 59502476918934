import React from 'react';
import ReactCalendar from 'react-calendar';
import { formatMonth } from 'react-calendar/dist/cjs/shared/dateFormatter';
import 'react-calendar/dist/Calendar.css';

import { dateToString } from 'utils/date';

import * as S from './styled';

interface CalendarProps {
  value: Date;
  onChange: (date: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ value, onChange }) => {
  return (
    <S.Container>
      <ReactCalendar calendarType="US" value={value} onChange={onChange as any} />
    </S.Container>
  );
};

export default Calendar;
