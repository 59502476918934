import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Classes = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Class = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 140px;
  max-width: 100%;
  width: 100%;
  cursor: pointer;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        flex-direction: column;
        align-items: center;
        height: unset;
        background: #f5f5f5;

        border-radius: 12px;
        padding: 8px;
      `,
      1
    )}

  & + & {
    margin-top: 48px;

    ${({ theme }) =>
      theme.screens.xs(
        css`
          margin-top: 24px;
        `,
        1
      )}
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 180px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ClassContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        align-items: center;
        margin-top: 8px;
        margin-left: 0;
      `,
      1
    )}
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const Description = styled.span`
  font-size: 12px;
  line-height: 15px;
  height: 45px;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: auto;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        margin-top: 8px;
      `,
      1
    )};
`;

export const Detail = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;

export const DetailTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #aeaeae;

  & > div {
    margin-right: 8px;
  }
`;

export const DetailValue = styled.span`
  text-align: center;
  margin-top: 4px;
  font-size: 11px;
  color: #313131;
`;

export const FavoriteContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100px;
  height: 100%;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        position: absolute;
        top: -12px;
        right: 12px;
        width: unset;
        height: unset;
        background: #fff;
      `,
      1
    )}
`;
