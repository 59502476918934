import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, TableList, Header, Button, TableListButtons, TableListButtonsProps } from 'components';
import { useService } from 'hooks';
import { dateToString } from 'utils/date';

const PAGE_KEY = 'challenge_page';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', '/challenge', { page: currentPage }, true, [currentPage]);
  const removeService = useService('delete', (data) => `/challenge/${data.id}`, {}, false);

  const handleOnSelect = (row: any) => {
    navigate({ pathname: `${location.pathname}/${row._id}/startups`, search: searchParams.toString() });
  };

  const handleOnEdit = (row: any) => {
    navigate({ pathname: `${location.pathname}/edit/${row._id}`, search: searchParams.toString() });
  };

  const handleOnRemove = async (row: any) => {
    const response = await removeService.fetch({}, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Curso salva com sucesso');
      return listService.fetch();
    }

    return toast('Houve um erro ao remover', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: 'pencil',
      label: 'Editar',
      onClick: handleOnEdit,
    },
    {
      icon: 'trash',
      label: 'Excluir',
      onClick: handleOnRemove,
    },
  ];

  const tableListConfig = [
    {
      title: 'Nome',
      size: {
        md: 5,
      },
      path: 'name',
    },
    {
      title: 'Empresa',
      size: {
        md: 3,
      },
      path: 'company',
    },
    {
      title: 'Expira em:',
      size: {
        md: 2,
      },
      path: (row: any) => dateToString(row.endDate),
    },
    {
      title: '',
      size: {
        md: 2,
      },
      path: (row: any) => <TableListButtons data={row} config={tableListButtonsConfig} />,
    },
  ];

  return (
    <Body>
      <Header title="Desafios" right={<Button to={`${location.pathname}/create`}>Novo desafio</Button>} />

      <TableList
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        onRowClick={handleOnSelect}
        dataPath="challenges.items"
        paginate
        paginatePath="challenges"
        onChangePage={handleOnChangePage}
      />
    </Body>
  );
};

export default List;
