import styled from 'styled-components';

import * as Types from './types';

export const Container = styled.div<Types.SpaceStyles>`
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};

  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`;
