import React from 'react';

import { Icon, Loader } from 'components';
import { dateToString } from 'utils/date';
import { useService } from 'hooks';

import NewSchedule from '../NewSchedule';
import * as S from './styled';

interface SchedulesProps {
  value: Date;
  type: 'STARTUP' | 'ADVISOR';
  startup?: string;
  advisor?: string;
}

const Schedules: React.FC<SchedulesProps> = ({ value, ...props }) => {
  const [showModal, setModal] = React.useState(false);
  const [selected, setSelected] = React.useState<any>();

  const listService = useService('get', '/schedule', { date: dateToString(value) }, false);

  const handleOnNew = () => {
    setModal(true);
  };

  const fetchList = () => {
    listService
      .fetch({
        date: dateToString(value),
      })
      .then((result) => {
        const data = result.data?.schedules?.items || [];

        setSelected(data?.[0]);
      });
  };

  const handleOnCloseModal = (refresh?: boolean) => {
    if (refresh) fetchList();

    setModal(false);
  };

  React.useEffect(() => {
    fetchList();
  }, [value]);

  const data = listService.data?.data?.schedules?.items || [];

  return (
    <Loader show={listService.loading}>
      <S.Container>
        <S.Header>
          <S.Title>Agendamentos</S.Title>

          <S.NewSchedule onClick={handleOnNew}>Novo</S.NewSchedule>
        </S.Header>

        <S.SubHeader>{dateToString(value, "EEEE, d 'de'  LLLL")}</S.SubHeader>

        <S.Content>
          {data.length ? null : <S.Empty>Nenhum agendamento</S.Empty>}
          <S.Times>
            {data?.map((item) => (
              <S.Time key={item._id} onClick={() => setSelected(item)} active={item._id === selected?._id}>
                {dateToString(item.scheduledAt, 'hh:mm')}
              </S.Time>
            ))}
          </S.Times>

          {!selected ? null : (
            <S.Schedule>
              <S.ScheduleTitle>{selected?.title}</S.ScheduleTitle>

              <S.SchedulePeople>
                <S.SchedulePerson>
                  <Icon name="profile-fill" color="black" />
                  {selected?.advisor.name}
                </S.SchedulePerson>

                <S.SchedulePerson>
                  <Icon name="profile-fill" color="black" />
                  {selected?.startup.name}
                </S.SchedulePerson>
              </S.SchedulePeople>
            </S.Schedule>
          )}
        </S.Content>

        {!showModal ? null : <NewSchedule {...props} date={value} onClose={handleOnCloseModal} />}
      </S.Container>
    </Loader>
  );
};

export default Schedules;
