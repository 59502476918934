import styled, { css } from 'styled-components';

import { ScreensType } from 'types/styled-components';
import { ScreensEnum } from 'types/styled-components/enuns';

export const Container = styled.div<{ spacing: number }>`
  display: flex;
  flex-direction: row;
  width: calc(100% + ${({ spacing }) => spacing}px);
  flex-wrap: wrap;
  justify-content: flex-start;
  transition: all ease 1s;
  row-gap: ${({ spacing }) => spacing}px;
  margin: 0 -${({ spacing }) => spacing / 2}px;
`;

export const Content = styled.div<{
  spacing: number;
  size: ScreensType;
  direction: 'row' | 'column';
  align?: 'flex-end' | 'flex-start' | 'center';
}>`
  ${({ spacing, theme, size, direction, align }) => css`
    display: flex;
    flex-wrap: wrap;
    transition: all ease 1s;
    flex-direction: ${direction};
    justify-content: ${align || 'flex-start'};
    width: calc(((100% / 12) * ${size.xl || size.lg || size.md || size.sm || size.xs || 12}) - ${spacing}px);
    margin: 0 ${spacing / 2}px;

    ${(Object.keys(size) as Array<ScreensEnum>)
      .map((key) => {
        return theme.screens[key](`width: calc(((100% / 12) * ${size?.[key] as number}) - ${spacing}px)`, size[key]);
      })
      .join(';')}
  `}
`;
