import React from 'react';
import { toast } from 'react-toastify';

import { TextField, FormStep, FormStepConfig, SelectField, Modal } from '../';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';
import validate from 'utils/validate';

const validations = {
  name: [validate.isEmpty()],
  foundationYear: [validate.isEmpty()],
  website: [validate.isEmpty()],
  city: [validate.isEmpty()],
  state: [validate.isEmptySelect()],
  'responsible.name': [validate.isEmpty(), validate.isName()],
  'responsible.linkedin': [validate.isEmpty()],
  'responsible.email': [validate.isEmpty(), validate.isEmail()],
  'responsible.phone': [validate.isEmpty(), validate.isCellphone()],
  stage: [validate.isEmptySelect()],
  activity: [validate.isEmptySelect()],
};

const StartupUpdateData: React.FC = () => {
  const { setAuth } = AuthContext.useAuth();

  const updateService = useService('patch', '/startup/my-startup', {}, false);
  const startupService = useService('get', '/startup/my-startup');

  const stateInfo = useService('get', '/info/STATE', { limit: 100 });
  const stageInfo = useService('get', '/info/STAGE', { limit: 100 });
  const activityInfo = useService('get', '/info/ACTIVITY', { limit: 100 });

  const [form, onChange] = useForm({
    validations,
  });

  const handleOnRegister = async () => {
    const data = {
      name: form.getValue('name'),
      foundationYear: form.getValue('foundationYear'),
      website: form.getValue('website'),
      city: form.getValue('city'),
      state: form.getValue('state')?._id,
      responsible: {
        name: form.getValue('responsible.name'),
        linkedin: form.getValue('responsible.linkedin'),
        email: form.getValue('responsible.email'),
        phone: form.getValue('responsible.phone'),
      },
      stage: form.getValue('stage')?._id,
      activity: form.getValue('activity')?._id,
    };

    const response = await updateService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Dados atualizados com sucesso');
      setAuth({});
      return;
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao atualizar os dados', { type: 'error' });
  };

  const formConfig: FormStepConfig = [
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.name',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o nome da Startup?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.foundationYear',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e seu ano de fundação?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            maskConfig: {
              integerLimit: 4,
            },
            mask: 'numeric',
          }),
        },
        {
          schema: 'startup.website',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o site?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.city',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Em qual cidade estão sediados?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.state',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'e o estado?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: stateInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.responsible.name',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o nome do CEO?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.responsible.linkedin',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o Linkedin dele?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.responsible.email',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o e-mail?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },

        {
          schema: 'startup.responsible.phone',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'e o número do Whatsapp dele?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'phone',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'startup.stage',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'Qual estágio da startup?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: stageInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'startup.activity',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'e o setor de atuação??',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: activityInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
  ];

  React.useEffect(() => {
    const data = startupService.data?.data?.startup;

    if (!data) return;

    form.setValues({
      name: data.name,
      website: data.website,
      city: data.city,
      state: data.state,
      foundationYear: data.foundationYear,
      'responsible.name': data?.responsible?.name,
      'responsible.email': data?.responsible?.email,
      'responsible.phone': data?.responsible?.phone,
      'responsible.linkedin': data?.responsible?.linkedin,
      stage: data.stage,
      activity: data.activity,
    });
  }, [startupService.data]);

  return (
    <Modal title="Precisamos o perfil da Startup" size={{ xs: 12, md: 6 }} loading={startupService.loading}>
      <FormStep
        config={formConfig}
        onSubmit={form.trySave(handleOnRegister)}
        loading={updateService.loading}
        errors={form.errors}
        showErrors={form.setTouched}
      />
    </Modal>
  );
};

export default StartupUpdateData;
