import React from 'react';
import { toast } from 'react-toastify';

import { FormGrid, FormConfig, TextField, SelectField, Footer, Button, Loader } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

const validations = {
  name: [validate.isEmpty()],
  foundationYear: [validate.isEmpty()],
  website: [validate.isEmpty()],
  city: [validate.isEmpty()],
  state: [validate.isEmptySelect()],
  'responsible.name': [validate.isEmpty(), validate.isName()],
  'responsible.linkedin': [validate.isEmpty()],
  'responsible.email': [validate.isEmpty(), validate.isEmail()],
  'responsible.phone': [validate.isEmpty(), validate.isCellphone()],
  stage: [validate.isEmptySelect()],
  activity: [validate.isEmptySelect()],
};

const MyStartup: React.FC = () => {
  const updateService = useService('patch', '/startup/my-startup', {}, false);
  const startupService = useService('get', '/startup/my-startup');

  const stateInfo = useService('get', '/info/STATE', { limit: 100 });
  const stageInfo = useService('get', '/info/STAGE', { limit: 100 });
  const activityInfo = useService('get', '/info/ACTIVITY', { limit: 100 });

  const [form, onChange] = useForm({
    validations,
  });

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      foundationYear: form.getValue('foundationYear'),
      website: form.getValue('website'),
      city: form.getValue('city'),
      state: form.getValue('state')?._id,
      responsible: {
        name: form.getValue('responsible.name'),
        linkedin: form.getValue('responsible.linkedin'),
        email: form.getValue('responsible.email'),
        phone: form.getValue('responsible.phone'),
      },
      stage: form.getValue('stage')?._id,
      activity: form.getValue('activity')?._id,
    };

    const response = await updateService.fetch(data);

    if (response?.data?.status === 'OK') {
      return toast('Dados salvos com sucesso');
    }

    if (response?.data?.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 9 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'foundationYear',
        size: { md: 3 },
        type: TextField,
        props: (schema) => ({
          label: 'Ano de fundação',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          maskConfig: {
            integerLimit: 4,
          },
          mask: 'numeric',
        }),
      },
      {
        schema: 'website',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Site',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'city',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Em qual cidade estão sediados?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'state',
        size: { md: 6 },
        type: SelectField,
        props: (schema) => ({
          label: 'e o estado?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: stateInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'responsible.name',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome do CEO',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'responsible.linkedin',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Linkedin do CEO',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'responsible.email',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'E-mail do CEO',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },

      {
        schema: 'responsible.phone',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Whatsapp do CEO',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'phone',
        }),
      },
    ],
    [
      {
        schema: 'stage',
        size: { md: 6 },
        type: SelectField,
        props: (schema) => ({
          label: 'Estágio',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: stageInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'activity',
        size: { md: 6 },
        type: SelectField,
        props: (schema) => ({
          label: 'Setor de atuação',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: activityInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],
  ];

  React.useEffect(() => {
    const data = startupService.data?.data?.startup;

    if (!data) return;

    form.setValues({
      name: data.name,
      website: data.website,
      city: data.city,
      state: data.state,
      foundationYear: data.foundationYear,
      'responsible.name': data?.responsible?.name,
      'responsible.email': data?.responsible?.email,
      'responsible.phone': data?.responsible?.phone,
      'responsible.linkedin': data?.responsible?.linkedin,
      stage: data.stage,
      activity: data.activity,
    });
  }, [startupService.data]);

  return (
    <>
      <Loader show={startupService.loading}>
        <FormGrid config={formConfig} />

        <Footer>
          <Button onClick={form.trySave(handleOnSubmit)} loading={startupService.loading || updateService.loading}>
            Salvar
          </Button>
        </Footer>
      </Loader>
    </>
  );
};

export default MyStartup;
