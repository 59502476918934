import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #777;
`;

export const Value = styled.div`
  min-height: 48px;
  width: 100%;
  padding: 8px 0;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  font-weight: bold;
`;
