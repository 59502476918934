import React from 'react';

import * as S from './styled';
import * as Types from './types';
import { getMask } from './config';

interface TextValueProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value?: string | number;
  mask?: Types.MaskEnum;
  maskConfig?: Types.MaskConfig;
}

const TextValue: React.FC<TextValueProps> = ({ label, value, mask, maskConfig }) => {
  const maskedValue = mask ? getMask(mask, value, maskConfig) : value;

  return (
    <S.Container>
      <S.Label>{label}</S.Label>

      <S.Value>{maskedValue || '-'}</S.Value>
    </S.Container>
  );
};

export default TextValue;
