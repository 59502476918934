import { Method } from 'axios';

import { AxiosRequestHeaders } from 'axios';

import api from 'services/api';
import { AuthContext } from 'contexts';

import useRequest from '../request';

const useService = <ResponseType = any, Params = any, RouteParams = any>(
  type: Method,
  route: string | ((params: RouteParams) => string),
  data?: Params | null,
  autoFetch: boolean = true,
  update?: any[]
) => {
  const { state } = AuthContext.useAuth();

  return useRequest<ResponseType, Params, RouteParams>(
    (params?: Params, routeParams?: RouteParams, headers: AxiosRequestHeaders = {}) => {
      const resolvedRoute = typeof route === 'function' ? route(routeParams as RouteParams) : route;

      const methodParams = ['get', 'options', 'head', 'delete'].includes(type)
        ? { params: !autoFetch ? params : data }
        : { data: !autoFetch ? params : data };

      return api.request({
        method: type,
        url: resolvedRoute,
        headers: {
          authorization: state?.token || '',
          ...headers,
        },
        ...methodParams,
      });
    },
    autoFetch,
    update
  );
};

export default useService;
