import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from 'contexts';
import { Theme } from 'components';

import Routes from './Routes';

const App = () => {
  return (
    <AuthContext.Provider>
      <Theme>
        <Routes />

        <ToastContainer position="top-center" />
      </Theme>
    </AuthContext.Provider>
  );
};

export default App;
