import React from 'react';
import { Routes, Route } from 'react-router-dom';

import BannerPng from 'assets/images/banner-my-startup.png';
import { Body, Header } from 'components';

import Profile from './profile';
import GeneralInfo from './generalInfo';
import InvestorProfile from './investorProfile';
import * as S from './styled';

const MyStartup: React.FC = () => {
  return (
    <>
      <Header title="My Startup" />

      <S.Container>
        <S.Content>
          <S.Banner src={BannerPng} />

          <S.Tabs>
            <S.Tab className={({ isActive }) => (isActive ? 'active' : undefined)} to="/startup/my-startup/">
              Perfil da Startup
            </S.Tab>
            <S.Tab
              className={({ isActive }) => (isActive ? 'active' : undefined)}
              to="/startup/my-startup/general-info">
              Informações Gerais
            </S.Tab>
            <S.Tab
              className={({ isActive }) => (isActive ? 'active' : undefined)}
              to="/startup/my-startup/investor-profile">
              Perfil para investimento
            </S.Tab>
          </S.Tabs>

          <Body>
            <S.Body>
              <Routes>
                <Route path="/" element={<Profile />} />
                <Route path="/general-info" element={<GeneralInfo />} />
                <Route path="/investor-profile" element={<InvestorProfile />} />
              </Routes>
            </S.Body>
          </Body>
        </S.Content>
      </S.Container>
    </>
  );
};

export default MyStartup;
