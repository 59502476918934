import { cpf, cnpj } from 'cpf-cnpj-validator';

import { dateToString } from 'utils/date';
import { formatMoney, decimalToString } from 'utils/number';
import * as Types from './types';

export const getMask = (variant: Types.MaskEnum, value?: string | number, config?: Types.MaskConfig) => {
  const masks = {
    default: () => value,
    numeric: () => value,
    date: () => dateToString(value as number, config?.dateFormat),
    money: () => formatMoney(value as number),
    decimal: () => decimalToString(value as number),
    percent: () => decimalToString(value as number) + '%',

    cpf: () => (value ? cpf.format(`${value}`) : ''),
    cnpj: () => (value ? cnpj.format(`${value}`) : ''),

    phone: () => value,
    'postal-code': () => value,
  };

  return masks[variant] ? masks[variant]() : masks.default();
};
