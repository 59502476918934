import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Modal, FormGrid, FormConfig, TextAreaField, Button } from 'components';
import { useForm, useService } from 'hooks';

interface NewCommentProps {
  onClose: (refresh?: boolean) => void;
}

const NewComment: React.FC<NewCommentProps> = ({ onClose }) => {
  const params = useParams();
  const [form, onChange] = useForm({});

  const createService = useService('post', `mentorship/${params.mentorship}/comment`, {}, false);

  const handleOnSubmit = async () => {
    const data = {
      description: form.getValue('description'),
    };

    const response = await createService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Comentário salvo com sucesso');
      return onClose(true);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'description',
        size: { md: 12 },
        type: TextAreaField,
        props: (schema) => ({
          label: 'Descrição',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
  ];

  return (
    <Modal
      size={{ md: 6 }}
      title="Novo comentário"
      Footer={
        <>
          <Button variant="outlined" onClick={onClose}>
            Voltar
          </Button>

          <Button onClick={form.trySave(handleOnSubmit)} loading={createService.loading}>
            Salvar
          </Button>
        </>
      }>
      <FormGrid config={formConfig} />
    </Modal>
  );
};

export default NewComment;
