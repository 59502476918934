import React from 'react';
import { toast } from 'react-toastify';

import { Modal, FormStep, FormStepConfig, TextField, TextAreaField, SelectField, Switch } from '../';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';
import validate from 'utils/validate';
import { stringToDecimal, decimalToString } from 'utils/number';

const validations = {
  valueOffer: [validate.isEmpty()],
  companyGoal: [validate.isEmpty()],
  target: [validate.isEmptySelect()],
  businessModel: [validate.isEmptySelect()],
  activeClients: [validate.isEmpty()],
  cnpj: [validate.isEmpty(), validate.isCNPJ()],
  linkedin: [validate.isEmpty()],
  instagram: [validate.isEmpty()],
  numberOfCollaborators: [validate.isEmpty()],
  investmentType: [validate.isEmptySelect()],
  pitchdeck: [validate.isEmpty()],
  pitchUrl: [validate.isEmpty()],
  'accelerationProgram.which': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
  'accelerationProgram.when': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
  'accelerationProgram.step': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
  'accelerationProgram.amount': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),

  interestValue: [validate.isEmpty()],
  percentageOffered: [validate.isEmpty()],
  founders: [validate.isEmpty()],
  annualGrossIncome: [validate.isEmpty()],
  breakeven: [validate.isEmpty()],
  grewBillingLastYear: [validate.isEmpty()],
  cac: [validate.isEmpty()],
  ltv: [validate.isEmpty()],
  profitMargin: [validate.isEmpty()],
  salesCycle: [validate.isEmpty()],
  churn: [validate.isEmpty()],
  marketSize: [validate.isEmpty()],
  percentageMarketSizeWantServe: [validate.isEmpty()],
};

const StartupUpdateData: React.FC = () => {
  const { setAuth } = AuthContext.useAuth();

  const updateService = useService('patch', '/startup/my-startup/info-profile', {}, false);
  const updateInvestorService = useService('patch', '/startup/my-startup/investor-profile', {}, false);
  const startupService = useService('get', '/startup/my-startup/info-profile');
  const startupInvestorService = useService('get', '/startup/my-startup/investor-profile');

  const targetInfo = useService('get', '/info/TARGET', { limit: 100 });
  const businessModelInfo = useService('get', '/info/BUSINESS_MODEL', { limit: 100 });
  const investimentTypeInfo = useService('get', '/info/INVESTIMENT_TYPE', { limit: 100 });
  const stepInfo = useService('get', '/info/STARTUP_STEP', { limit: 100 });

  const [form, onChange] = useForm({
    validations,
  });

  const handleOnSubmit = async () => {
    const data = {
      valueOffer: form.getValue('valueOffer'),
      companyGoal: form.getValue('companyGoal'),
      target: form.getValue('target')?._id,
      businessModel: form.getValue('businessModel')?._id,
      activeClients: form.getValue('activeClients'),
      cnpj: form.getValue('cnpj'),
      linkedin: form.getValue('linkedin'),
      instagram: form.getValue('instagram'),
      numberOfCollaborators: form.getValue('numberOfCollaborators'),
      investmentType: form.getValue('investmentType')?._id,
      pitchdeck: form.getValue('pitchdeck'),
      pitchUrl: form.getValue('pitchUrl'),
      accelerationProgram: {
        participate: form.getValue('accelerationProgram.participate'),
        which: form.getValue('accelerationProgram.which'),
        when: form.getValue('accelerationProgram.when'),
        step: form.getValue('accelerationProgram.step')?._id,
        amount: form.getValue('accelerationProgram.amount'),
      },
    };

    const response = await updateService.fetch(data);

    const investorDdata = {
      interestedInInvesting: form.getValue('interestedInInvesting'),
      interestValue: stringToDecimal(form.getValue('interestValue')),
      percentageOffered: stringToDecimal(form.getValue('percentageOffered')),
      founders: form.getValue('founders'),
      annualGrossIncome: stringToDecimal(form.getValue('annualGrossIncome')),
      breakeven: form.getValue('breakeven'),
      grewBillingLastYear: stringToDecimal(form.getValue('grewBillingLastYear')),
      cac: stringToDecimal(form.getValue('cac')),
      ltv: form.getValue('ltv'),
      profitMargin: stringToDecimal(form.getValue('profitMargin')),
      salesCycle: form.getValue('salesCycle'),
      churn: form.getValue('churn'),
      marketSize: form.getValue('marketSize'),
      percentageMarketSizeWantServe: stringToDecimal(form.getValue('percentageMarketSizeWantServe')),
    };

    const responseInvestor = await updateInvestorService.fetch(investorDdata);

    if (response?.data?.status === 'OK' && responseInvestor?.data?.status === 'OK') {
      toast('Dados atualizados com sucesso');
      setAuth({});
      return;
    }

    if (response?.data?.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    if (responseInvestor?.data?.status === 'ERROR') {
      form.setErrors(responseInvestor.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormStepConfig = [
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'cnpj',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o CNPJ da startup?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'cnpj',
          }),
        },
        {
          schema: 'linkedin',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o LinkedIn da startup?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'instagram',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o Instagram da startup? ',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'numberOfCollaborators',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Quantos funcionários possuem atualmente?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'investmentType',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'A startup já recebeu investimentos?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            options: investimentTypeInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'pitchdeck',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Envie seu pitchdeck (URL do google drive, por exemplo)',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'pitchUrl',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Envie seu vídeo pitch preferencialmente de um minuto (URL youtube)',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'accelerationProgram.participate',
          size: { md: 12 },
          type: Switch,
          props: (schema) => ({
            label: 'A startup participa/participou de algum programa de aceleração?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema, [
              'accelerationProgram.which',
              'accelerationProgram.when',
              'accelerationProgram.step',
              'accelerationProgram.amount',
            ]),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'accelerationProgram.which',
          size: { md: 12 },
          type: TextField,
          hide: () => !form.getValue('accelerationProgram.participate'),
          props: (schema) => ({
            label: 'Qual?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'accelerationProgram.when',
          size: { md: 12 },
          type: TextField,
          hide: () => !form.getValue('accelerationProgram.participate'),
          props: (schema) => ({
            label: 'Quando?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'accelerationProgram.step',
          size: { md: 12 },
          type: SelectField,
          hide: () => !form.getValue('accelerationProgram.participate'),
          props: (schema) => ({
            label: 'Quais contrapartidas?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            options: stepInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
          }),
        },
        {
          schema: 'accelerationProgram.amount',
          size: { md: 12 },
          type: TextField,
          hide: () => !form.getValue('accelerationProgram.participate'),
          props: (schema) => ({
            label: 'Quanto?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'money',
          }),
        },
      ],
    },
    {
      title: 'Dados importantes para manter seu cadastro atualizado',
      fields: [
        {
          schema: 'valueOffer',
          size: { md: 12 },
          type: TextAreaField,
          props: (schema) => ({
            label: 'Qual a proposta de valor da startup para seus clientes?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'companyGoal',
          size: { md: 12 },
          type: TextAreaField,
          props: (schema) => ({
            label: 'Qual o problema que resolvem e como fazem isso?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'target',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'Qual seu público-alvo?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            options: targetInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
          }),
        },
        {
          schema: 'businessModel',
          size: { md: 12 },
          type: SelectField,
          props: (schema) => ({
            label: 'E modelo de negócio?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            options: businessModelInfo?.data?.data?.info?.items || [],
            format: {
              value: '_id',
              label: 'name',
            },
          }),
        },
        {
          schema: 'activeClients',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Quantos clientes ativos possuem?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            maskConfig: {
              integerLimit: 4,
            },
            mask: 'numeric',
          }),
        },
      ],
    },
    {
      title: 'Informações para investimento',
      legend:
        'Tela final de cadastro, com informações sensíveis, necessárias para requisição de conexão com mentores e investidores cadastrados na plataforma. ',
      fields: [
        {
          schema: 'interestedInInvesting',
          size: { md: 12 },
          type: Switch,
          props: (schema) => ({
            label: 'A startup está buscando investimentos?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'interestValue',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual o valor da rodada que buscam?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'money',
          }),
        },
        {
          schema: 'percentageOffered',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Por qual porcentagem da empresa?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'percent',
          }),
        },
      ],
    },
    {
      title: 'Informações para investimento',
      legend:
        'Tela final de cadastro, com informações sensíveis, necessárias para requisição de conexão com mentores e investidores cadastrados na plataforma. ',
      fields: [
        {
          schema: 'founders',
          size: { md: 12 },
          type: TextAreaField,
          props: (schema) => ({
            label: 'Descreva o time de founders',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'annualGrossIncome',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual a receita bruta que teve somada nos últimos 12 meses? (ou ARR) ',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'money',
          }),
        },
      ],
    },
    {
      title: 'Informações para investimento',
      legend:
        'Tela final de cadastro, com informações sensíveis, necessárias para requisição de conexão com mentores e investidores cadastrados na plataforma. ',
      fields: [
        {
          schema: 'breakeven',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'A startup já atingiu o ponto de equilíbrio – Breakeven?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'grewBillingLastYear',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Quanto cresceu o faturamento nos últimos 12 meses? ',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'money',
          }),
        },
        {
          schema: 'cac',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual seu CAC (Custo de Aquisição por cliente)?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'money',
          }),
        },

        {
          schema: 'ltv',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual seu LTV (life time value)?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
      ],
    },
    {
      title: 'Informações para investimento',
      legend:
        'Tela final de cadastro, com informações sensíveis, necessárias para requisição de conexão com mentores e investidores cadastrados na plataforma. ',
      fields: [
        {
          schema: 'profitMargin',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual sua margem média mensal neste período?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'money',
          }),
        },
        {
          schema: 'salesCycle',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Ciclo de vendas (Tempo para fazer uma venda)?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'churn',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual sua taxa de churn?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'marketSize',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual tamanho estimado do mercado?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
          }),
        },
        {
          schema: 'percentageMarketSizeWantServe',
          size: { md: 12 },
          type: TextField,
          props: (schema) => ({
            label: 'Qual a porcentagem desse mercado que buscam atender?',
            value: form.getValue(schema),
            error: form.getError(schema),
            onChange: onChange(schema),
            isRequired: true,
            mode: 'dark',
            mask: 'percent',
          }),
        },
      ],
    },
  ];

  React.useEffect(() => {
    const data = startupService.data?.data?.infoProfile;
    const dataInvestor = startupService.data?.data?.investorProfile;

    if (!data || !dataInvestor) return;

    form.setValues({
      valueOffer: data?.valueOffer,
      companyGoal: data?.companyGoal,
      target: data?.target,
      businessModel: data?.businessModel,
      activeClients: data?.activeClients,
      cnpj: data?.cnpj,
      linkedin: data?.linkedin,
      instagram: data?.instagram,
      numberOfCollaborators: data?.numberOfCollaborators,
      investmentType: data?.investmentType,
      pitchdeck: data?.pitchdeck,
      pitchUrl: data?.pitchUrl,
      'accelerationProgram.participate': data?.accelerationProgram?.participate,
      'accelerationProgram.which': data?.accelerationProgram?.which,
      'accelerationProgram.when': data?.accelerationProgram?.when,
      'accelerationProgram.step': data?.accelerationProgram?.step,
      'accelerationProgram.amount': data?.accelerationProgram?.amount,

      interestedInInvesting: data?.interestedInInvesting,
      interestValue: decimalToString(data?.interestValue),
      percentageOffered: decimalToString(data?.percentageOffered),
      founders: data?.founders,
      annualGrossIncome: decimalToString(data?.annualGrossIncome),
      breakeven: data?.breakeven,
      grewBillingLastYear: decimalToString(data?.grewBillingLastYear),
      cac: decimalToString(data?.cac),
      ltv: data?.ltv,
      profitMargin: decimalToString(data?.profitMargin),
      salesCycle: data?.salesCycle,
      churn: data?.churn,
      marketSize: data?.marketSize,
      percentageMarketSizeWantServe: decimalToString(data?.percentageMarketSizeWantServe),
    });
  }, [startupInvestorService.data, startupService.data]);

  return (
    <Modal size={{ xs: 12, md: 6 }} loading={startupService.loading}>
      <FormStep
        config={formConfig}
        onSubmit={form.trySave(handleOnSubmit)}
        loading={updateService.loading}
        errors={form.errors}
        showErrors={form.setTouched}
      />
    </Modal>
  );
};

export default StartupUpdateData;
