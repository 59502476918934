import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AuthContext } from 'contexts';

import * as S from './styled';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const { state } = AuthContext.useAuth();

  React.useEffect(() => {
    if (state.token && state.isLogged && state?.user?.type) {
      const LINKS = {
        STARTUP: '/startup',
        ADMIN: '/admin',
        ADVISOR: '/advisor',
      };

      navigate(LINKS[state.user.type]);
    }
  }, [state]);

  return (
    <S.Container>
      <S.Content>
        <Outlet />
      </S.Content>
    </S.Container>
  );
};

export default Auth;
