import React from 'react';
import { useTheme } from 'styled-components';
import ReactSelect, {
  components,
  IndicatorsContainerProps,
  Props,
  MultiValueProps,
  SingleValueProps,
} from 'react-select';
import ReactTooltip from 'react-tooltip';

import BaseInput from '../BaseInput';
import Icon from '../Icon';
import * as S from './styled';

const defaultFormat = { value: 'value', label: 'label' };
type Option = {
  [key: string]: any;
  value?: string;
  label?: string;
};

interface SelectFieldProps extends Omit<Props, 'onChange' | 'options' | 'value'> {
  label: string;
  tooltip?: string;
  onChange: (value?: MultiValueProps | SingleValueProps) => void;
  isRequired?: boolean;
  error?: string;
  mode: 'light' | 'dark';
  format?: {
    value?: string;
    label?: string;
  };
  value?: Option;
  options: Array<Option>;
  disabled?: boolean;
  loading?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  tooltip,
  value,
  onChange,
  isRequired,
  error,
  mode = 'light',
  options,
  format = defaultFormat,
  disabled,
  loading,
  ...props
}) => {
  const theme = useTheme();
  const id = React.useId();
  const selectFieldId = `text-field-${id}`;

  const currentFormat = {
    ...defaultFormat,
    ...format,
  };

  const handleOnChange = (values) => {
    onChange?.(values);
  };

  const customStyles = {
    container: (prev) => ({
      ...prev,
      width: '100%',
    }),
    control: (prev) => ({
      ...prev,
      height: '48px',
      color: S.getMode(theme)[mode].input.color,
      borderColor: S.getMode(theme)[mode].input.border,
      boxShadow: 'none',
      '&:active': {
        borderColor: S.getMode(theme)[mode].input.border,
      },
      '&:hover': {
        borderColor: S.getMode(theme)[mode].input.border,
      },
    }),
    indicatorsContainer: (prev) => ({
      ...prev,
      display: 'flex',
      flexDirection: 'row',
    }),
    indicatorSeparator: (prev) => ({
      ...prev,
      display: 'none',
    }),
    valueContainer: (prev) => ({
      ...prev,
      padding: '10px 15px',
    }),
    input: (prev) => ({
      ...prev,
      fontSize: '14px',
      color: S.getMode(theme)[mode].input.color,
      margin: '0',
    }),
    singleValue: (prev) => ({
      ...prev,
      fontSize: '14px',
      color: S.getMode(theme)[mode].input.color,
      margin: '0',
    }),
    menu: (prev) => ({
      ...prev,
      marginTop: 'none',
    }),
  };

  const formatedOptions = React.useMemo(() => {
    return options?.map((item) => ({
      ...item,
      value: item?.[currentFormat.value],
      label: item?.[currentFormat.label],
    }));
  }, [options]);

  const formatedValue = React.useMemo(() => {
    return !value
      ? value
      : {
          ...value,
          value: value?.[currentFormat.value],
          label: value?.[currentFormat.label],
        };
  }, [value]);

  const IndicatorsContainer: React.FC<IndicatorsContainerProps> = ({ children, ...rest }) => {
    return (
      <components.IndicatorsContainer {...rest}>
        {children}

        {!tooltip ? null : (
          <S.Tooltip>
            <S.TooltipIcon data-tip data-for={selectFieldId}>
              <Icon name="info-circle" size={18} />
            </S.TooltipIcon>

            <ReactTooltip id={selectFieldId} place="top" type="dark" effect="solid">
              <span>{tooltip}</span>
            </ReactTooltip>
          </S.Tooltip>
        )}
      </components.IndicatorsContainer>
    );
  };

  return (
    <BaseInput error={error} mode={mode}>
      <S.Container>
        <S.Label mode={mode}>
          {label}
          {isRequired ? ' *' : ''}
        </S.Label>

        <S.InputContent>
          <ReactSelect
            styles={customStyles}
            placeholder=""
            noOptionsMessage={() => 'Lista vazia'}
            components={{ IndicatorsContainer }}
            isClearable
            onChange={handleOnChange}
            value={formatedValue}
            options={formatedOptions}
            isDisabled={disabled}
            isLoading={loading}
            {...props}
          />
        </S.InputContent>
      </S.Container>
    </BaseInput>
  );
};

export default SelectField;
