import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TextField, Footer, FormGrid, FormConfig, Header, Button, SelectField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

const validations = {
  startup: [validate.isEmptySelect()],
  launchDate: [validate.isDate()],
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form, onChange] = useForm({ validations });

  const LIST_CLASSES = `/admin/mentorships/availables`;

  const createService = useService('post', ({ startup }) => `/mentorship/startup/${startup}`, {}, false);
  const patchService = useService('patch', ({ startup }) => `/mentorship/startup/${startup}`, {}, false);
  const startupService = useService('get', `/mentorship/startup/${params.startup}`, {}, !!params.startup);
  const startupsService = useService(
    'get',
    `/startup`,
    { filters: { isAvailableMentorship: false }, limit: 1000 },
    true
  );

  const handleOnSave = async () => {
    const data = {
      launchDate: form.getValue('launchDate'),
      employees: form.getValue('employees'),
      ownership: form.getValue('ownership'),
      marketCap: form.getValue('marketCap'),
      netDebt: form.getValue('netDebt'),
      firmValuation: form.getValue('firmValuation'),
      address: form.getValue('address'),
      website: form.getValue('website'),
    };

    const route = {
      startup: form.getValue('startup')?._id,
    };

    const response = params.startup ? await patchService.fetch(data, route) : await createService.fetch(data, route);

    if (response?.data?.status === 'OK') {
      toast('Startup adicionada com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'startup',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'Startup',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: startupsService?.data?.data?.startups?.items || [],
          loading: startupsService.loading,
          format: {
            value: '_id',
            label: 'name',
          },
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'launchDate',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Data de abertura',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mask: 'date',
          mode: 'dark',
        }),
      },
      {
        schema: 'employees',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Funcionários',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
      {
        schema: 'ownership',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Capital',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'marketCap',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Valor de mercado',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
      {
        schema: 'netDebt',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Dívida liquida',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
      {
        schema: 'firmValuation',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Avaliação da empresa',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'address',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Localização',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
      {
        schema: 'website',
        size: { md: 4 },
        type: TextField,
        props: (schema) => ({
          label: 'Site',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
  ];

  React.useEffect(() => {
    const data = startupService.data?.data?.mentorship;

    if (!data) return;

    form.setValues({
      ...data,
    });
  }, [startupService.data]);

  return (
    <>
      <Header
        title={'Adicionar startup para mentoria'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
