import styled from 'styled-components';

import { Grid } from 'components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: #edf1f6;
  padding: 24px;
  margin-top: 48px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TableHeader = styled(Grid)`
  height: 48px;
  align-items: center;
  border-bottom: 1px solid #ddd;
`;

export const TableTitle = styled(Grid)`
  font-size: 12px;
  color: #b5b7c0;
`;

export const TableRow = styled(Grid)`
  padding: 16px 0;
  align-items: center;
  cursor: pointer;
`;

export const Empty = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: #b5b7c0;
`;

export const TableColumn = styled(Grid)``;

export const StartupData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background: #d9d9d969;
  gap: 10px;
  padding: 12px 24px;
`;

export const StartupLogo = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 13px;
`;

export const StartupName = styled.span`
  font-size: 12px;
`;

export const ColumnValue = styled.span`
  font-size: 14px;
`;

export const AdvisorLogo = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  object-fit: cover;
`;
