import React from 'react';

import { Body, Header } from 'components';

const Home: React.FC = () => {
  return (
    <Body>
      <Header title="Home" />
    </Body>
  );
};

export default Home;
