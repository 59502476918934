import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './list';
import Form from './form';

import Startups from './startup';

const Courses: React.FC = () => {
  return (
    <Routes>
      {/* /admin/challenges */}
      <Route path="/" element={<List />} />
      <Route path="/create" element={<Form />} />
      <Route path="/edit/:challenge" element={<Form />} />

      <Route path="/:challenge/startups/*" element={<Startups />} />
    </Routes>
  );
};

export default Courses;
