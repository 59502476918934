import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const getMode = () => ({
  light: { label: { color: '#fff' } },
  dark: { label: { color: '#666' } },
});
export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  /* max-width: 800px; */
  width: 100%;
  flex-direction: column;
`;

export const Banner = styled.img`
  width: 100%;
`;

export const FormSeparator = styled.span.attrs(() => ({ modes: getMode() }))<{ mode: 'light' | 'dark' }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  color: ${({ modes, mode }) => modes[mode].label.color};
  padding: 16px 0px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;

  &:after {
    display: flex;
    flex: 1;
    content: ' ';
    display: block;
    height: 1px;
    border-bottom: 1px solid ${({ modes, mode }) => modes[mode].label.color};
    margin-left: 16px;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Tab = styled(NavLink)`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  padding: 12px 0;
  border-bottom: 2px solid #ddd;

  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.dark};
  }

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Body = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
`;
