import React from 'react';

import * as S from './styled';
import Icon, { IconEnum } from '../Icon';

interface ButtonProps {
  icon: IconEnum;
  label?: string;
  onClick: (row: any) => void;
}

export interface TableListButtonsProps {
  data: any;
  config: Array<ButtonProps>;
}

const TableListButtons: React.FC<TableListButtonsProps> = ({ data, config }) => {
  const handleOnClick = (action: (row: any) => void) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    action?.(data);
  };

  return (
    <S.Container>
      {config?.map((item, key) => (
        <S.Button key={key} onClick={handleOnClick(item.onClick)}>
          {item.label && <S.Label>{item.label}</S.Label>}

          <Icon name={item.icon} />
        </S.Button>
      ))}
    </S.Container>
  );
};

export default TableListButtons;
