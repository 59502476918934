import styled, { DefaultTheme } from 'styled-components';

const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
    input: {
      color: '#777',
      border: theme.palette.primary.main,
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
    input: {
      color: '#666666',
      border: theme.palette.primary.main,
    },
  },
});
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ mode, modes }) => modes[mode].label.color};
`;

export const InputContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const Input = styled.textarea.attrs(({ theme }) => ({ modes: getMode(theme) }))<{
  disabled: boolean;
  rows: number;
  mode: 'light' | 'dark';
}>`
  min-height: 48px;
  min-width: 100%;
  max-width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 28px;
  border: 1px solid ${({ theme, modes, mode }) => modes[mode].input.border};
  color: ${({ mode, modes }) => modes[mode].input.color};
  background: ${({ disabled }) => (disabled ? '#ddd' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  outline: none;
  resize: none;

  &:focus {
    border: 1px solid #222;
  }
`;

export const Legend = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 12px;
  line-height: 16px;
  color: ${({ mode, modes }) => modes[mode].label.color};
  margin-bottom: 4px;
`;

export const Tooltip = styled.div`
  position: absolute;
  top: 13px;
  right: 8px;
`;

export const TooltipIcon = styled.span``;
