import React from 'react';

import * as S from './styled';

interface TextValueHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

const TextValueHeader: React.FC<TextValueHeaderProps> = ({ label }) => {
  return (
    <S.Container>
      <S.Label>{label}</S.Label>
    </S.Container>
  );
};

export default TextValueHeader;
