import React from 'react';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { Auth, Platform, Admin, Advisor } from 'templates';
import { Auth as AuthPages, Startup, Admin as AdminPages, Advisor as AdvisorPages } from 'pages';
import { PrivateRoute } from 'components';

const RoutesComponent: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/logout" element={<AuthPages.Logout />} />

          <Route path="/" element={<Auth />}>
            <Route path="/" element={<AuthPages.Login />} />
            <Route path="/change-password/:token" element={<AuthPages.ChangePassword />} />
            <Route path="/forgot-password" element={<AuthPages.ForgotPassword />} />
            <Route path="/startup/register" element={<AuthPages.Register />} />
          </Route>

          <Route path="startup/*" element={<Platform />}>
            <Route path="integrations/*" element={<PrivateRoute type="STARTUP" Page={Startup.Integrations} />} />
            <Route path="startup-start/*" element={<PrivateRoute type="STARTUP" Page={Startup.StartupStart} />} />
            <Route path="match-making" element={<PrivateRoute type="STARTUP" Page={Startup.Matchmaking} />} />
            <Route path="mentorship/*" element={<PrivateRoute type="STARTUP" Page={Startup.Mentorship} />} />
            <Route path="my-startup/*" element={<PrivateRoute type="STARTUP" Page={Startup.MyStartup} />} />

            <Route path="*" element={<Navigate to="/startup/my-startup/" replace />} />
          </Route>

          <Route path="advisor/*" element={<Advisor />}>
            <Route path="dashboard" element={<PrivateRoute type="ADVISOR" Page={AdvisorPages.Dashboard} />} />
            <Route path="feedbacks/*" element={<PrivateRoute type="ADVISOR" Page={AdvisorPages.Feedbacks} />} />
            <Route path="integrations/*" element={<PrivateRoute type="ADVISOR" Page={AdvisorPages.Integrations} />} />
            <Route path="mentorship/*" element={<PrivateRoute type="ADVISOR" Page={AdvisorPages.Mentorship} />} />
            <Route path="schedules/*" element={<PrivateRoute type="ADVISOR" Page={AdvisorPages.Schedules} />} />
            <Route path="startups/*" element={<PrivateRoute type="ADVISOR" Page={AdvisorPages.Startups} />} />

            <Route path="*" element={<Navigate to="/advisor/dashboard" replace />} />
          </Route>

          <Route path="admin/*" element={<Admin />}>
            <Route index element={<PrivateRoute type="ADMIN" Page={AdminPages.Home} />} />
            <Route path="advisors/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.Advisors} />} />
            <Route path="admins/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.Admins} />} />
            <Route path="challenges/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.Challenges} />} />
            <Route path="courses/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.Courses} />} />
            <Route path="mentorships/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.Mentorships} />} />
            <Route path="startups/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.Startups} />} />
            <Route path="startup-start/*" element={<PrivateRoute type="ADMIN" Page={AdminPages.StartupStart} />} />

            <Route path="*" element={<Navigate to="/admin" replace />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
