import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TextField, Button, FormGrid, FormConfig, Space, Icon } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

import * as S from './styled';

const Register: React.FC = () => {
  const navigate = useNavigate();

  const forgotService = useService('post', '/auth/new-password', {}, false);

  const [form, onChange] = useForm({
    validations: {
      email: [validate.isEmpty(), validate.isEmail()],
    },
  });

  const handleOnRemember = async () => {
    const data = {
      email: form.getValue('email'),
    };
    const response = await forgotService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('E-mail enviado com sucesso');

      return navigate('/');
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao enviar em-ail', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'email',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
        }),
      },
    ],
  ];
  return (
    <>
      <S.IconContent>
        <Icon name="padlock" color="white" size={100} />
      </S.IconContent>

      <S.Container>
        <FormGrid config={formConfig} />

        <Space top={16} />
        <Button
          variant="gradient"
          color="white"
          width="100%"
          onClick={form.trySave(handleOnRemember)}
          loading={forgotService.loading}>
          Enviar link de recuperação
        </Button>

        <Space top={56} />
        <Button variant="transparent" color="white" width="100%" to="/">
          Voltar
        </Button>
      </S.Container>
    </>
  );
};

export default Register;
