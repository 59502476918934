import styled, { css } from 'styled-components';

export const Container = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  margin: ${({ noMargin }) => (noMargin ? 0 : 20)}px 0;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        margin-top: 0;
        gap: 8px;
        padding: 8px;
      `,
      1
    )}
`;

export const Buttons = styled.div<{ align: 'flex-start' | 'flex-end' }>`
  display: flex;
  flex-direction: row;
  width: 30%;
  align-items: center;
  justify-content: ${({ align }) => align};
  gap: 8px;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        width: max-content;
      `,
      1
    )}
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        justify-content: unset;
      `,
      1
    )}
`;
