import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, Footer, FormGrid, FormConfig, Header, Button, TextField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';
import { stringToDate, dateToString } from 'utils/date';

const LIST_COURSES = '/admin/courses';

const validations = {
  name: [validate.isEmpty()],
  time: [validate.isEmpty(), validate.isDate('HH:mm:ss', 'Duração inválida')],
  description: [validate.isEmpty()],
  author: [validate.isEmpty()],
  // image: [validate.isEmpty()],
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ course?: string }>();
  const [searchParams] = useSearchParams();

  const [form, onChange] = useForm({ validations });

  const createService = useService('post', '/course', {}, false);
  const updateService = useService('patch', `/course/${params.course}`, {}, false);
  const courseService = useService('get', `/course/${params.course}`, {}, !!params.course);

  const handleOnSave = async () => {
    const data = {
      name: form.getValue('name'),
      time: stringToDate(form.getValue('time'), 'HH:ss:mm'),
      description: form.getValue('description'),
      author: form.getValue('author'),
      image: form.getValue('image'),
    };

    const service = params.course ? updateService : createService;

    const response = await service.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Curso salvo com sucesso');
      return navigate({ pathname: LIST_COURSES, search: searchParams.toString() });
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  React.useEffect(() => {
    const data = courseService?.data?.data?.course;

    if (!data) return;

    form.setValues({
      ...data,
      time: dateToString(data.time, 'HH:ss:mm'),
    });
  }, [courseService.data]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 9 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
      {
        schema: 'time',
        size: { md: 3 },
        type: TextField,
        props: (schema) => ({
          label: 'Duração',
          mask: 'hhmmss',
          placeholder: 'HH:MM:SS',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'description',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Descrição',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'author',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Autor',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
      {
        schema: 'image',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Imagem',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
  ];

  return (
    <Body>
      <Header
        title={params.course ? 'Editar curso' : 'Novo curso'}
        left={
          <Button variant="outlined" to={`${LIST_COURSES}?${searchParams.toString()}`}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </Body>
  );
};

export default Form;
