import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'contexts';

interface PrivateRouteProps {
  Page: any;
  type: 'ADMIN' | 'ADVISOR' | 'STARTUP';
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ type, Page }) => {
  const navigate = useNavigate();
  const { state } = AuthContext.useAuth();

  React.useEffect(() => {
    if (!state.token || !state.isLogged) {
      navigate('/');
    }
  }, [state]);

  if (state?.user?.type !== type) return <>Pagina indisponivel</>;

  return <Page />;
};

export default PrivateRoute;
