import React from 'react';

import * as S from './styled';
import * as Types from './types';

const Space: React.FC<Types.SpaceProps> = ({ children, top, left, bottom, right, direction, ...props }) => {
  return (
    <S.Container
      marginTop={top}
      marginRight={right}
      marginLeft={left}
      marginBottom={bottom}
      flexDirection={direction}
      {...props}>
      {children}
    </S.Container>
  );
};

export default Space;
