import React from 'react';

import Startups from './components/Startups';
import * as S from './styled';

const Dashboard: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <Startups />
      </S.Content>
    </S.Container>
  );
};

export default Dashboard;
