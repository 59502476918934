import styled from 'styled-components';

export const Container = styled.div``;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  color: #777;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
`;
