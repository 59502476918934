import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, Footer, FormGrid, FormConfig, Header, Button, TextField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

const validations = {
  name: [validate.isEmpty()],
  email: [validate.isEmpty(), validate.isEmail()],
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ advisor?: string }>();
  const [form, onChange] = useForm({ validations });
  const isEdit = !!params.advisor;

  const LIST_CLASSES = `/admin/advisors`;

  const createService = useService('post', `/advisor`, {}, false);
  const updateService = useService('patch', `/advisor/${params.advisor}`, {}, false);
  const advisorService = useService('get', `/advisor/${params.advisor}`, {}, !!params.advisor);

  const handleOnSave = async () => {
    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
    };

    const service = params.advisor ? updateService : createService;
    const response = await service.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Investidor salvo com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  React.useEffect(() => {
    const data = advisorService?.data?.data?.advisor;

    if (!data) return;

    form.setValues({
      ...data,
    });
  }, [advisorService.data]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'email',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Email',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          disabled: isEdit,
          mode: 'dark',
        }),
      },
    ],
  ];

  return (
    <Body>
      <Header
        title={params.advisor ? 'Editar orientador' : 'Novo orientador'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </Body>
  );
};

export default Form;
