import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './list';
import Form from './form';

const Admins: React.FC = () => {
  return (
    <Routes>
      {/* /admin/mentorship/orienteds */}
      <Route path="/" element={<List />} />
      <Route path="/create" element={<Form />} />
    </Routes>
  );
};

export default Admins;
