import styled from 'styled-components';

export const Link = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 4px;
`;

export const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 80vh;
`;
