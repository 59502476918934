export const getFilters = (searchParams) => {
  try {
    const filters = JSON.parse(searchParams || '');

    if (!filters) return {};

    return filters;
  } catch {
    return {};
  }
};
