import React from 'react';

import BaseInput from '../BaseInput';
import Icon from '../Icon';
import * as S from './styled';

interface FileFieldProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string;
  value?: File;
  onChange: (value?: File) => void;
  isRequired?: boolean;
  error?: string;
  disabled?: boolean;
  mode: 'light' | 'dark';
}

const FileField: React.FC<FileFieldProps> = ({ label, value, onChange, error, mode = 'light', disabled = false }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e?.target?.files?.[0]);
  };

  const handleOnOpen = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    inputRef?.current?.click();
  };

  const handleOnClear = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (inputRef.current) inputRef.current.value = '';
    onChange?.(undefined);
  };

  const filename = value?.name || 'Clique para selecionar';

  return (
    <BaseInput error={error} mode={mode}>
      <S.Container>
        <S.Label>{label}</S.Label>

        <S.InputContent disabled={disabled} onClick={handleOnOpen}>
          <S.FileName>{filename}</S.FileName>

          <Icon name="close" size={24} onClick={handleOnClear} />
        </S.InputContent>

        <input ref={inputRef} type="file" onChange={handleOnChange} hidden multiple={false} />
      </S.Container>
    </BaseInput>
  );
};

export default FileField;
