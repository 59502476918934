import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './list';

const Courses: React.FC = () => {
  return (
    <Routes>
      {/* /admin/challenges/:challenge/startups */}
      <Route path="/" element={<List />} />
    </Routes>
  );
};

export default Courses;
