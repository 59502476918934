import React from 'react';
import * as R from 'ramda';

import { useState } from 'hooks';
import { getBase64File } from 'utils/file';

import BaseInput from '../BaseInput';
import Icon from '../Icon';
import * as S from './styled';

export interface ImageType {
  base64?: string;
  path?: string;
}

interface PhotoInputProps {
  label: string;
  value?: ImageType;
  error?: string;
  onChange?: (value?: ImageType) => void;
  mode: 'light' | 'dark';
}

interface PhotoInputState {
  value?: ImageType;
}

const PhotoInput: React.FC<PhotoInputProps> = ({ label, value: valueProp, error, onChange, mode = 'light' }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = useState<PhotoInputState>({ value: undefined });

  const handleOnSelectImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    inputRef?.current?.click();
  };

  const handleOnChange = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) {
      return;
    }

    const value = (await getBase64File(e.target.files[0])) as ImageType;

    setState({ value });
    onChange?.(value);
  };

  React.useEffect(() => {
    if (!R.equals(state.value, valueProp)) {
      setState({ value: valueProp });
    }
  }, [valueProp]);

  return (
    <>
      <BaseInput error={error} mode={mode}>
        <S.Container>
          <S.Label mode={mode}>{label}</S.Label>

          <S.ImageContent mode={mode} onClick={handleOnSelectImage}>
            {state.value ? null : <S.AddIcon as={Icon} name="plus" size={44} />}

            {!state.value?.path ? null : <S.Image src={state.value.path} />}
          </S.ImageContent>
        </S.Container>

        <input ref={inputRef} type="file" hidden accept=".png,.jpg, .jpeg" onChange={handleOnChange} />
      </BaseInput>
    </>
  );
};

export default PhotoInput;
