import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Footer, FormGrid, FormConfig, Header, Button, SelectField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

const validations = {
  startup: [validate.isEmptySelect()],
  advisor: [validate.isEmptySelect()],
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const [form, onChange] = useForm({ validations });

  const LIST_CLASSES = `/admin/mentorships/orienteds`;

  const createService = useService('post', `/mentorship`, {}, false);
  const startupsService = useService('get', `/mentorship/startup`, { limit: 1000 });
  const advisorsService = useService('get', `/advisor`, { limit: 1000 });

  const handleOnSave = async () => {
    const data = {
      startup: form.getValue('startup')?.value,
      advisor: form.getValue('advisor')?.value,
    };

    const response = await createService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Mentoria criada com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'startup',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'Startup disponíveis',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: startupsService?.data?.data?.startups?.items || [],
          loading: startupsService.loading,
          mode: 'dark',
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
    ],
    [
      {
        schema: 'advisor',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'Orientadores',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: advisorsService?.data?.data?.advisors?.items || [],
          loading: advisorsService.loading,
          mode: 'dark',
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
    ],
  ];

  return (
    <>
      <Header
        title={'Adicionar mentoria'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
