import React from 'react';

import { useState } from 'hooks';

import Button from '../Button';
import Modal from '../Modal';

interface FilterButtonProps {
  Filter: any;
  clearOnChange?: Array<string>;
}

const FilterButton: React.FC<FilterButtonProps> = ({ Filter, clearOnChange }) => {
  const [state, setState] = useState({ showModal: false });

  const handleOnClose = () => {
    setState({ showModal: false });
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setState({ showModal: true })}>
        Filtrar
      </Button>

      <Modal show={state.showModal} title="Filtros" size={{ md: 6 }} onClose={handleOnClose}>
        <Filter onClose={handleOnClose} clearOnChange={clearOnChange} />
      </Modal>
    </>
  );
};

export default FilterButton;
