import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'contexts';
import { Loader } from 'components'

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setLogout } = AuthContext.useAuth();

  React.useEffect(() => {
    setLogout();

    setTimeout(() => {
      navigate('/');
    }, 1500);
  });

  return <Loader show/>;
};

export default Login;
