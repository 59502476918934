import React from 'react';
import MaskedInput from 'react-text-mask';
import ReactTooltip from 'react-tooltip';

import BaseInput from '../BaseInput';
import Icon from '../Icon';
import * as S from './styled';
import * as Types from './types';
import { getMask } from './config';

interface TextFieldProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string;
  legend?: string;
  tooltip?: string;
  value?: string | number;
  onChange: (value?: string) => void;
  isRequired?: boolean;
  error?: string;
  mask?: Types.MaskEnum;
  maskConfig?: Types.MaskConfig;
  disabled?: boolean;
  mode: 'light' | 'dark';
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  legend,
  tooltip,
  value,
  onChange,
  isRequired,
  error,
  mask,
  maskConfig,
  disabled = false,
  mode = 'light',
  ...props
}) => {
  const id = React.useId();
  const textFieldId = `text-field-${id}`;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    onChange?.(value);
  };

  const maskProps = mask ? { as: MaskedInput, ...getMask(mask, maskConfig) } : {};

  return (
    <BaseInput error={error} mode={mode}>
      <S.Container>
        <S.Label mode={mode}>
          {label}

          {isRequired ? ' *' : ''}
        </S.Label>

        {!legend ? null : <S.Legend>{legend}</S.Legend>}

        <S.InputContent>
          <S.Input
            {...maskProps}
            disabled={disabled}
            type="text"
            onChange={handleOnChange}
            value={value || ''}
            mode={mode}
            {...props}
          />

          {!tooltip ? null : (
            <S.Tooltip>
              <S.TooltipIcon data-tip data-for={textFieldId}>
                <Icon name="info-circle" size={18} />
              </S.TooltipIcon>

              <ReactTooltip id={textFieldId} place="top" type="dark" effect="solid">
                <span>{tooltip}</span>
              </ReactTooltip>
            </S.Tooltip>
          )}
        </S.InputContent>
      </S.Container>
    </BaseInput>
  );
};

export default TextField;
