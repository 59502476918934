import React from 'react';
import { toast } from 'react-toastify';

import {
  FormGrid,
  FormConfig,
  TextField,
  TextAreaField,
  SelectField,
  Footer,
  Button,
  Loader,
  Switch,
} from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

const validations = {
  valueOffer: [validate.isEmpty()],
  companyGoal: [validate.isEmpty()],
  target: [validate.isEmptySelect()],
  businessModel: [validate.isEmptySelect()],
  activeClients: [validate.isEmpty()],
  cnpj: [validate.isEmpty(), validate.isCNPJ()],
  linkedin: [validate.isEmpty()],
  instagram: [validate.isEmpty()],
  numberOfCollaborators: [validate.isEmpty()],
  investmentType: [validate.isEmptySelect()],
  pitchdeck: [validate.isEmpty()],
  pitchUrl: [validate.isEmpty()],
  'accelerationProgram.which': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
  'accelerationProgram.when': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
  'accelerationProgram.step': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
  'accelerationProgram.amount': validate.validateIf(
    (form) => form?.['accelerationProgram.participate'],
    [validate.isEmpty()]
  ),
};

const MyStartup: React.FC = () => {
  const updateService = useService('patch', '/startup/my-startup/info-profile', {}, false);
  const startupService = useService('get', '/startup/my-startup/info-profile');

  const targetInfo = useService('get', '/info/TARGET', { limit: 100 });
  const businessModelInfo = useService('get', '/info/BUSINESS_MODEL', { limit: 100 });
  const investimentTypeInfo = useService('get', '/info/INVESTIMENT_TYPE', { limit: 100 });
  const stepInfo = useService('get', '/info/STARTUP_STEP', { limit: 100 });

  const [form, onChange] = useForm({
    validations,
  });

  const handleOnSubmit = async () => {
    const data = {
      valueOffer: form.getValue('valueOffer'),
      companyGoal: form.getValue('companyGoal'),
      target: form.getValue('target')?._id,
      businessModel: form.getValue('businessModel')?._id,
      activeClients: form.getValue('activeClients'),
      cnpj: form.getValue('cnpj'),
      linkedin: form.getValue('linkedin'),
      instagram: form.getValue('instagram'),
      numberOfCollaborators: form.getValue('numberOfCollaborators'),
      investmentType: form.getValue('investmentType')?._id,
      pitchdeck: form.getValue('pitchdeck'),
      pitchUrl: form.getValue('pitchUrl'),
      accelerationProgram: {
        participate: form.getValue('accelerationProgram.participate'),
        which: form.getValue('accelerationProgram.which'),
        when: form.getValue('accelerationProgram.when'),
        step: form.getValue('accelerationProgram.step')?._id,
        amount: form.getValue('accelerationProgram.amount'),
      },
    };

    const response = await updateService.fetch(data);

    if (response?.data?.status === 'OK') {
      return toast('Dados salvos com sucesso');
    }

    if (response?.data?.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'valueOffer',
        size: { md: 12 },
        type: TextAreaField,
        props: (schema) => ({
          label: 'Qual a proposta de valor da startup para seus clientes?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'companyGoal',
        size: { md: 12 },
        type: TextAreaField,
        props: (schema) => ({
          label: 'Qual o problema que resolvem e como fazem isso?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'target',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'Qual seu público-alvo?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          options: targetInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
      {
        schema: 'businessModel',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'E modelo de negócio?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          options: businessModelInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
      {
        schema: 'activeClients',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Quantos clientes ativos possuem?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          maskConfig: {
            integerLimit: 4,
          },
          mask: 'numeric',
        }),
      },
      {
        schema: 'cnpj',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual o CNPJ da startup?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'cnpj',
        }),
      },
      {
        schema: 'linkedin',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual o LinkedIn da startup?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'instagram',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual o Instagram da startup? ',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'numberOfCollaborators',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Quantos funcionários possuem atualmente?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },

      {
        schema: 'investmentType',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'A startup já recebeu investimentos?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: investimentTypeInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'pitchdeck',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Envie seu pitchdeck (URL do google drive, por exemplo)',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'pitchUrl',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Envie seu vídeo pitch preferencialmente de um minuto (URL youtube)',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.participate',
        size: { md: 12 },
        type: Switch,
        props: (schema) => ({
          label: 'A startup participa/participou de algum programa de aceleração?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema, [
            'accelerationProgram.which',
            'accelerationProgram.when',
            'accelerationProgram.step',
            'accelerationProgram.amount',
          ]),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.which',
        size: { md: 12 },
        type: TextField,
        hide: () => !form.getValue('accelerationProgram.participate'),
        props: (schema) => ({
          label: 'Qual?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.when',
        size: { md: 12 },
        type: TextField,
        hide: () => !form.getValue('accelerationProgram.participate'),
        props: (schema) => ({
          label: 'Quando?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.step',
        size: { md: 12 },
        type: SelectField,
        hide: () => !form.getValue('accelerationProgram.participate'),
        props: (schema) => ({
          label: 'Quais contrapartidas?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          options: stepInfo?.data?.data?.info?.items || [],
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
      {
        schema: 'accelerationProgram.amount',
        size: { md: 12 },
        type: TextField,
        hide: () => !form.getValue('accelerationProgram.participate'),
        props: (schema) => ({
          label: 'Quanto?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'money',
        }),
      },
    ],
  ];

  React.useEffect(() => {
    const data = startupService.data?.data?.infoProfile;

    if (!data) return;

    form.setValues({
      valueOffer: data?.valueOffer,
      companyGoal: data?.companyGoal,
      target: data?.target,
      businessModel: data?.businessModel,
      activeClients: data?.activeClients,
      cnpj: data?.cnpj,
      linkedin: data?.linkedin,
      instagram: data?.instagram,
      numberOfCollaborators: data?.numberOfCollaborators,
      investmentType: data?.investmentType,
      pitchdeck: data?.pitchdeck,
      pitchUrl: data?.pitchUrl,
      'accelerationProgram.participate': data?.accelerationProgram?.participate,
      'accelerationProgram.which': data?.accelerationProgram?.which,
      'accelerationProgram.when': data?.accelerationProgram?.when,
      'accelerationProgram.step': data?.accelerationProgram?.step,
      'accelerationProgram.amount': data?.accelerationProgram?.amount,
    });
  }, [startupService.data]);

  return (
    <>
      <Loader show={startupService.loading}>
        <FormGrid config={formConfig} />

        <Footer>
          <Button onClick={form.trySave(handleOnSubmit)} loading={startupService.loading || updateService.loading}>
            Salvar
          </Button>
        </Footer>
      </Loader>
    </>
  );
};

export default MyStartup;
