import axios from 'axios';

const { REACT_APP_API_URI } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export default api;
