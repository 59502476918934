import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, TableList, Header, Button, TableListButtons, TableListButtonsProps } from 'components';
import { useService } from 'hooks';

const PAGE_KEY = 'admin_page';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/admin`, { page: currentPage }, true, [currentPage]);
  const removeService = useService('delete', (data) => `/admin/${data?.id}`, {}, false);

  const handleOnEdit = (row: any) => {
    navigate(`${location.pathname}/edit/${row._id}`);
  };

  const handleOnRemove = async (row: any) => {
    const response = await removeService.fetch({}, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Aula salva com sucesso');
      return listService.fetch();
    }

    return toast('Houve um erro ao remover', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: 'pencil',
      label: 'Editar',
      onClick: handleOnEdit,
    },
    {
      icon: 'trash',
      label: 'Excluir',
      onClick: handleOnRemove,
    },
  ];

  const tableListConfig = [
    {
      title: 'Nome',
      size: {
        md: 6,
      },
      path: 'name',
    },
    {
      title: 'Email',
      size: {
        md: 4,
      },
      path: 'email',
    },

    {
      title: '',
      size: {
        md: 2,
      },
      path: (row: any) => <TableListButtons data={row} config={tableListButtonsConfig} />,
    },
  ];

  return (
    <Body>
      <Header
        title="Usuários administrativos"
        right={<Button to={`${location.pathname}/create`}>Novo usuário</Button>}
      />

      <TableList
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        dataPath="admins.items"
        paginate
        paginatePath="admins"
        onChangePage={handleOnChangePage}
      />
    </Body>
  );
};

export default List;
