import React from 'react';
import { Outlet } from 'react-router-dom';

import Menu from './components/Menu';
import Sidebar from './components/Sidebar';
import * as S from './styled';

const Advisor: React.FC = () => {
  return (
    <S.Container>
      <Menu />

      <S.Content>
        <S.Body>
          <Outlet />
        </S.Body>

        <Sidebar />
      </S.Content>
    </S.Container>
  );
};

export default Advisor;
