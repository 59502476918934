import React from 'react';
import { toast } from 'react-toastify';

import { FormGrid, FormConfig, TextField, Footer, Button, Loader, TextAreaField, Switch } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';
import { stringToDecimal, decimalToString } from 'utils/number';

const validations = {
  interestValue: [validate.isEmpty()],
  percentageOffered: [validate.isEmpty()],
  founders: [validate.isEmpty()],
  annualGrossIncome: [validate.isEmpty()],
  breakeven: [validate.isEmpty()],
  grewBillingLastYear: [validate.isEmpty()],
  cac: [validate.isEmpty()],
  ltv: [validate.isEmpty()],
  profitMargin: [validate.isEmpty()],
  salesCycle: [validate.isEmpty()],
  churn: [validate.isEmpty()],
  marketSize: [validate.isEmpty()],
  percentageMarketSizeWantServe: [validate.isEmpty()],
};

const MyStartup: React.FC = () => {
  const updateService = useService('patch', '/startup/my-startup/investor-profile', {}, false);
  const startupService = useService('get', '/startup/my-startup/investor-profile');

  const [form, onChange] = useForm({
    validations,
  });

  const handleOnSubmit = async () => {
    const data = {
      interestedInInvesting: form.getValue('interestedInInvesting'),
      interestValue: stringToDecimal(form.getValue('interestValue')),
      percentageOffered: stringToDecimal(form.getValue('percentageOffered')),
      founders: form.getValue('founders'),
      annualGrossIncome: stringToDecimal(form.getValue('annualGrossIncome')),
      breakeven: form.getValue('breakeven'),
      grewBillingLastYear: stringToDecimal(form.getValue('grewBillingLastYear')),
      cac: stringToDecimal(form.getValue('cac')),
      ltv: form.getValue('ltv'),
      profitMargin: stringToDecimal(form.getValue('profitMargin')),
      salesCycle: form.getValue('salesCycle'),
      churn: form.getValue('churn'),
      marketSize: form.getValue('marketSize'),
      percentageMarketSizeWantServe: stringToDecimal(form.getValue('percentageMarketSizeWantServe')),
    };

    const response = await updateService.fetch(data);

    if (response?.data?.status === 'OK') {
      return toast('Dados salvos com sucesso');
    }

    if (response?.data?.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'interestedInInvesting',
        size: { md: 12 },
        type: Switch,
        props: (schema) => ({
          label: 'A startup está buscando investimentos?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'interestValue',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual o valor da rodada que buscam?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'money',
        }),
      },
      {
        schema: 'percentageOffered',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Por qual porcentagem da empresa?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'percent',
        }),
      },
    ],
    [
      {
        schema: 'founders',
        size: { md: 12 },
        type: TextAreaField,
        props: (schema) => ({
          label: 'Descreva o time de founders',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'annualGrossIncome',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual a receita bruta que teve somada nos últimos 12 meses? (ou ARR) ',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'money',
        }),
      },
    ],
    [
      {
        schema: 'breakeven',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'A startup já atingiu o ponto de equilíbrio – Breakeven?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'grewBillingLastYear',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Quanto cresceu o faturamento nos últimos 12 meses? ',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'money',
        }),
      },
      {
        schema: 'cac',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual seu CAC (Custo de Aquisição por cliente)?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'money',
        }),
      },

      {
        schema: 'ltv',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual seu LTV (life time value)?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'profitMargin',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual sua margem média mensal neste período?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'money',
        }),
      },
      {
        schema: 'salesCycle',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Ciclo de vendas (Tempo para fazer uma venda)?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'churn',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual sua taxa de churn?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'marketSize',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual tamanho estimado do mercado?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'percentageMarketSizeWantServe',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Qual a porcentagem desse mercado que buscam atender?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
          mask: 'percent',
        }),
      },
    ],
  ];

  React.useEffect(() => {
    const data = startupService.data?.data?.investorProfile;

    if (!data) return;

    form.setValues({
      interestedInInvesting: data?.interestedInInvesting,
      interestValue: decimalToString(data?.interestValue),
      percentageOffered: decimalToString(data?.percentageOffered),
      founders: data?.founders,
      annualGrossIncome: decimalToString(data?.annualGrossIncome),
      breakeven: data?.breakeven,
      grewBillingLastYear: decimalToString(data?.grewBillingLastYear),
      cac: decimalToString(data?.cac),
      ltv: data?.ltv,
      profitMargin: decimalToString(data?.profitMargin),
      salesCycle: data?.salesCycle,
      churn: data?.churn,
      marketSize: data?.marketSize,
      percentageMarketSizeWantServe: decimalToString(data?.percentageMarketSizeWantServe),
    });
  }, [startupService.data]);

  return (
    <>
      <Loader show={startupService.loading}>
        <FormGrid config={formConfig} />

        <Footer>
          <Button onClick={form.trySave(handleOnSubmit)} loading={startupService.loading || updateService.loading}>
            Salvar
          </Button>
        </Footer>
      </Loader>
    </>
  );
};

export default MyStartup;
