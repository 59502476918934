import React from 'react';

import Button from '../Button';
import FormGrid from '../FormGrid';
import * as Types from './types';
import * as S from './styled';

const FormStep: React.FC<Types.FormStepProps> = ({ config, onSubmit, loading, errors, showErrors, ...props }) => {
  const [step, setStep] = React.useState(0);

  const currentStep = React.useMemo(() => config[step], [step, config]);

  const currentErrors = React.useMemo(() => {
    const schemas = currentStep.fields.map((item) => item.schema);

    return schemas.filter((schema) => !!errors?.[schema]);
  }, [currentStep, errors]);

  const handleOnBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const handleOnNext = () => {
    if (currentErrors.length) return showErrors(currentErrors);

    if (step < config.length - 1) return setStep(step + 1);

    onSubmit();
  };

  return (
    <S.Container {...props}>
      {!currentStep.title ? null : <S.Title>{currentStep.title}</S.Title>}

      {!currentStep.legend ? null : <S.Legend>{currentStep.legend}</S.Legend>}

      <S.Steps>
        {config.map((item, key) => (
          <S.Step key={key} isActive={key === step} width={100 / (config.length + 1)} />
        ))}
      </S.Steps>

      <FormGrid config={[currentStep.fields]} />

      <S.Footer>
        <Button disabled={step === 0} variant="outlined" onClick={handleOnBack}>
          Voltar
        </Button>

        <Button onClick={handleOnNext} loading={loading}>
          Avançar
        </Button>
      </S.Footer>
    </S.Container>
  );
};

export default FormStep;

export type FormStepConfig = Array<Types.FormStepStep>;
