import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ColorsEnum, PaletteColorsEnum } from 'types/styled-components/enuns';

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 300px;
  height: 100%;

  ${({ theme, isOpen }) =>
    theme.screens.xs(
      `
        width: 100%;
        padding: 12px 24px;
        height: 56px;
        overflow: hidden;
        background: #fff;

        ${
          !isOpen
            ? 'position: relative'
            : `
              position: absolute;
              z-index: 999;
              top: 0;
              left: 0;
              height: 100%;
            `
        }
      `,
      1
    )}
`;

export const LogoContent = styled.div`
  display: flex;
  width: 100%;
`;

export const Logo = styled.span`
  display: flex;
  width: 38px;
  height: 41px;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        display: flex;
        width: 32px;
        height: 32px;
      `,
      1
    )}
`;

export const StartupContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 32px;
  padding-left: 4px;
`;

export const StartupAvatar = styled.span`
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0px 10px 10px #00000022;
`;

export const StartupDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  padding-left: 12px;
  justify-content: space-around;
`;

export const StartupName = styled.span`
  font-size: 18px;
  line-height: 22px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StartupDate = styled.span`
  font-size: 12px;
  line-height: 15px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 40px;
`;

export const Option = styled(Link)<{ noBorder?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 24px 0px;
  align-items: center;
  transition: ease 0.4s;
  ${({ noBorder }) => (noBorder ? '' : 'border-bottom: 1px solid #ddd')};

  &:hover,
  &:active {
    ${({ noBorder }) => (noBorder ? '' : 'border-bottom: 1px solid #777')};
  }

  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.dark};
  }
`;

export const OptionIcon = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 24px;
`;

export const OptionLabel = styled.span`
  font-size: 14px;
  line-height: 15px;
`;

export const Externals = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  padding-right: 0px;
  justify-content: flex-end;
`;

export const External = styled(Link)`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ececec;
  align-items: center;
  margin: 16px 0;
  padding: 8px 0;
  transition: 0.4s;

  &:hover {
    border-bottom: 1px solid #333;
  }

  &,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.dark};
  }
`;

export const ExternalColor = styled.div<{ color: ColorsEnum | PaletteColorsEnum }>`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  background: ${({ color, theme }) => theme.helpers.getColor(color)};
`;

export const ExternalLabel = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 12px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
`;

export const RequestInvestment = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 43px;
  background: ${({ theme }) => theme.palette.colors.blue.main};
  border-radius: 0 14px 14px 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 10px 10px #00000033;
`;

export const RequestInvestmentLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.light};
`;

export const RequestInvestmentIcon = styled.span`
  width: 18px;
  height: 18px;
  margin-left: 12px;
`;
