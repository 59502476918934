import styled from 'styled-components';

import { ColorsEnum, PaletteColorsEnum } from 'types/styled-components/enuns';

interface IconProps {
  color: ColorsEnum | PaletteColorsEnum;
}

export const Container = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

export const Icon = styled.span<IconProps>`
  & path {
    fill: ${({ theme, color }) =>
      theme?.palette?.colors?.[color as ColorsEnum]?.main || theme?.palette?.[color as PaletteColorsEnum]?.main};
  }
`;
