import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  background: ${({ theme }) => theme.palette.gradient.main};
  color: ${({ theme }) => theme.palette.colors.purple.text};
  margin-top: 12px;
  margin-bottom: 8px;
  padding: 4px 8px;
`;
