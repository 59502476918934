import React from 'react';

import { Calendar, Schedules, Icon } from 'components';
import { newDate } from 'utils/date';
import { AuthContext } from 'contexts';

import * as S from './styled';

const avatarUrl = 'https://www.shareicon.net/download/2016/05/24/770083_man_512x512.png';

const Sidebar: React.FC = () => {
  const { state } = AuthContext.useAuth();

  const [date, setDate] = React.useState(newDate());

  return (
    <S.Container>
      <S.Profile>
        <Icon name="bell" color="black" />

        <S.UserName>
          Eder Zadravec <Icon size={14} name="chevron-down" color="black" />
        </S.UserName>

        <S.Avatar src={avatarUrl} alt="Avatar" />
      </S.Profile>

      <Calendar value={date} onChange={setDate} />

      <Schedules type="ADVISOR" value={date} advisor={state?.user?.id} />
    </S.Container>
  );
};

export default Sidebar;
