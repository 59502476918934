import React from 'react';

import * as S from './styled';
import * as Types from './types';

export interface BodyProps {
  children: any;
  background?: Types.Color;
  opacity?: number;
  noPadding?: boolean;
  padding?: number;
  radius?: number;
}

const Body: React.FC<BodyProps> = ({
  background = 'white',
  noPadding = false,
  padding = 24,
  opacity,
  children,
  radius = 10,
}) => {
  return (
    <S.Container>
      <S.Content background={background} opacity={opacity} noPadding={noPadding} radius={radius} padding={padding}>
        {children}
      </S.Content>
    </S.Container>
  );
};

export default Body;
