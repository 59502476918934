import React from 'react';
import { useSearchParams } from 'react-router-dom';
import query from 'query-string';
import * as R from 'ramda';

import { FormGrid, FormConfig, TextField, Body, Button, Footer, Header } from 'components';
import { useForm } from 'hooks';
import { getFilters } from 'utils/filters';

interface FiltersProps {
  onClose: () => void;
  clearOnChange?: Array<string>;
}

const Filters: React.FC<FiltersProps> = ({ onClose, clearOnChange = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form, onChange] = useForm({});

  const parsed = query.parse(searchParams.toString());

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
  ];

  const handleOnSubmit = () => {
    const filters = JSON.stringify({
      name: form.getValue('name') as string,
    });

    setSearchParams({ ...R.omit(clearOnChange, parsed), filters });

    onClose();
  };

  const handleOnClear = () => {
    setSearchParams(query.stringify(R.omit(['filters', ...clearOnChange], parsed)));

    onClose();
  };

  React.useEffect(() => {
    const filters = getFilters(parsed.filters);

    form.setValues(filters);
  }, []);

  return (
    <>
      <Header title="Filtros" noMargin />

      <FormGrid config={formConfig} />

      <Footer align="space-between" padding={8}>
        <Button variant="outlined" color="white" onClick={handleOnClear}>
          Limpar
        </Button>

        <Button onClick={handleOnSubmit}>Aplicar</Button>
      </Footer>
    </>
  );
};

export default Filters;
