import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Mentorship as MentorshipPage } from 'pages/common';

const Mentorship: React.FC = () => {
  return (
    <Routes>
      {/* /advisor/mentorship */}
      <Route path="/:mentorship" element={<MentorshipPage />} />
    </Routes>
  );
};

export default Mentorship;
