import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.span`
  display: block;
  padding: 0 15%;
  text-align: center;
  font-size: 15px;
  line-height: 24px; /* 160% */
  letter-spacing: 0.25px;
`;

export const Legend = styled.span`
  display: block;
  padding: 0 15%;
  text-align: center;
  font-size: 11px;
  line-height: 12px; /* 160% */
  letter-spacing: 0.25px;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 24px 0;
`;

export const Step = styled.div<{ isActive: boolean; width: number }>`
  ${({ isActive, width }) => `
    width: ${width}%;
    height: 4px;
    background: ${isActive ? '#34AD09' : '#17171740'};
    border-radius: 2px;
    
  `}
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;
