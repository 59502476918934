import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 12px;
  padding: 8px;
`;

export const Pages = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  gap: 4px;
`;

export const Page = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.colors.purple.main};
  color: ${({ theme }) => theme.palette.colors.purple.text};
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
  transition: ease 0.2s;

  ${({ active, disabled }) => {
    if (active) {
      return css`
        cursor: not-allowed;
        color: ${({ theme }) => theme.palette.colors.purple.main};
        border: 1px solid ${({ theme }) => theme.palette.colors.purple.main};
        background: ${({ theme }) => theme.palette.colors.purple.text};
      `;
    }

    if (disabled) {
      return css`
        cursor: not-allowed;
        border: 1px solid ${({ theme }) => theme.palette.disabled.main};
        background: ${({ theme }) => theme.palette.disabled.text};

        & path {
          fill: ${({ theme }) => theme.palette.primary.main} !important;
        }
      `;
    }

    return css`
      cursor: pointer;

      & path {
        fill: ${({ theme }) => theme.palette.colors.purple.text} !important;
      }

      &:hover {
        transform: translateY(-4px) scale(1.1);
      }
    `;
  }}
`;
