import React from 'react';

import { ScreensType } from 'types/styled-components';
import Icon from '../Icon';
import Loader from '../Loader';

import * as S from './styled';

interface ModalProps {
  show?: boolean;
  size: ScreensType;
  title?: string;
  onClose?: () => void;
  Footer?: any;
  children: any;
  noScroll?: boolean;
  loading?: boolean;
}

const Modal: React.FC<ModalProps> = ({ show = true, size, title, onClose, Footer, noScroll, loading, children }) => {
  if (!show) return null;

  return (
    <S.Mask container>
      <S.Modal size={size}>
        {!onClose ? null : (
          <S.CloseButton onClick={onClose}>
            <Icon name="close" color="black" size={24} />
          </S.CloseButton>
        )}

        {!title ? null : <S.Header>{title}</S.Header>}

        <S.Container noScroll={noScroll}>
          <Loader show={loading}>
            <S.Content>{children}</S.Content>
          </Loader>

          {!Footer ? null : <S.Footer>{Footer}</S.Footer>}
        </S.Container>
      </S.Modal>
    </S.Mask>
  );
};

export default Modal;
