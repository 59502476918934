import React from 'react';
import * as R from 'ramda';

import { Icon } from 'components';

import * as Types from '../../types';
import * as S from './styled';

interface PaginateProps extends Types.Paginate {
  onChange?: (page: number) => void;
}

const PAGE_BUTTONS_LIMIT = 5;

const Paginate: React.FC<PaginateProps> = ({ page = 1, pages = 1, total = 0, onChange }) => {
  const handleOnChange = (item: number) => {
    page !== item && onChange?.(item);
  };

  const pageButtons = React.useMemo(() => {
    if (pages <= PAGE_BUTTONS_LIMIT) return R.range(1, pages + 1);

    const padPage = Math.trunc(PAGE_BUTTONS_LIMIT / 2);
    let middlePage = page;

    if (page <= padPage) middlePage = padPage + 1;
    if (page + padPage >= pages) middlePage = pages - padPage;

    const startPage = middlePage - padPage;
    const endPage = middlePage + padPage;

    const startRange = startPage >= 1 ? startPage : 1;
    const endRange = endPage <= pages ? endPage : pages;

    return R.range(startRange, endRange + 1);
  }, [total, pages, page]);

  return (
    <S.Container>
      <S.Pages>
        <S.Page disabled={page === 1} onClick={() => handleOnChange(page - 1)}>
          <Icon name="chevron-left" size={14} />
        </S.Page>

        {pageButtons.map((item) => (
          <S.Page key={item} active={page === item} onClick={() => handleOnChange(item)}>
            {item}
          </S.Page>
        ))}

        <S.Page disabled={page === pages} onClick={() => handleOnChange(page + 1)}>
          <Icon name="chevron-right" size={14} />
        </S.Page>
      </S.Pages>
    </S.Container>
  );
};

export default Paginate;
