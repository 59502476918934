import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100%;
  padding: 8px;
`;

export const Header = styled.div`
  padding: 40px 20px;
`;

export const Logo = styled.span`
  display: flex;
  width: 38px;
  height: 41px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > a {
    text-decoration: none;
  }
`;

export const Option = styled.div<{ noBorder?: boolean; isActive?: boolean }>`
  display: flex;
  flex-direction: row;
  height: 50px;
  padding: 0px 36px;
  align-items: center;
  transition: ease 0.4s;
  border-radius: 25px;
  color: ${({ theme }) => theme.palette.text.dark};

  &,
  &:active,
  &:hover,
  &:visited {
  }

  ${({ isActive }) =>
    !isActive
      ? ''
      : css`
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
          background: #fff;
        `}
`;

export const OptionIcon = styled.span`
  height: 14px;
  width: 14px;
  margin-right: 24px;
`;

export const OptionLabel = styled.span`
  font-size: 14px;
  line-height: 15px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;
