import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Tabs from './Tabs';

const Mentorship: React.FC = () => {
  return (
    <Routes>
      {/* /admin/mentorship */}
      <Route path="/:schema/*" element={<Tabs />} />
    </Routes>
  );
};

export default Mentorship;
