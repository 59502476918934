import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
  width: calc(100% - 300px);
`;
