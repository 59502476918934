import React from 'react';

import Comments from './components/Comments';
import Feedbacks from './components/Feedbacks';
import NewFeedback from './components/NewFeedback';
import StartupData from './components/StartupData';
import Uploads from './components/Uploads';

const Mentorship: React.FC = () => {
  const [showAddFeedback, setAddFeedback] = React.useState(false);
  const feedbackRef = React.useRef<any>();

  const handleOnCloseFeedbackForm = (refresh?: boolean) => {
    setAddFeedback(false);
    if (refresh) feedbackRef.current?.refresh();
  };

  return (
    <>
      <StartupData OnAddFeedback={() => setAddFeedback(true)} />

      <Feedbacks ref={feedbackRef} />

      <Uploads />

      <Comments />

      {!showAddFeedback ? null : <NewFeedback onClose={handleOnCloseFeedbackForm} />}
    </>
  );
};

export default Mentorship;
