import styled, { DefaultTheme } from 'styled-components';

const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
    input: {
      color: '#777',
      border: theme.palette.primary.main,
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
    input: {
      color: '#666666',
      border: theme.palette.primary.main,
    },
  },
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ mode, modes }) => modes[mode].label.color};
`;

export const ImageContent = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{
  mode: 'light' | 'dark';
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid ${({ theme, modes, mode }) => modes[mode].input.border};
  background: #fff;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  transition: 0.4s;
  overflow: hidden;

  &:hover {
    opacity: 0.8;
  }
`;

export const AddIcon = styled.span``;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
