import React from 'react';

import BaseInput from '../BaseInput';
import * as S from './styled';

interface SwitchProps {
  label: string;
  error?: string;
  mode?: 'light' | 'dark';
  value?: boolean;
  onChange: (newValue: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({ label, error, value = false, mode = 'light', onChange }) => {
  const handleOnClick = () => {
    onChange?.(!value);
  };

  return (
    <BaseInput error={error} mode={mode} direction="row">
      <S.Container>
        <S.Switch value={value} mode={mode} onClick={handleOnClick}>
          <S.Ball value={value} mode={mode} />
        </S.Switch>

        <S.Label mode={mode}>{label}</S.Label>
      </S.Container>
    </BaseInput>
  );
};

export default Switch;
