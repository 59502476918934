import React from 'react';

import BaseInput from '../BaseInput';
import * as S from './styled';

export interface BoxInputProps {
  label: string | React.ReactNode;
  value?: boolean;
  error?: string;
  onChange: (value?: boolean) => void;
  mode?: 'light' | 'dark';
}

const BoxInput: React.FC<BoxInputProps> = ({ label, value, error, onChange, mode = 'light' }) => {
  const handleOnChange = () => {
    onChange(!value);
  };

  return (
    <BaseInput error={error} mode={mode}>
      <S.Container onClick={handleOnChange}>
        <S.Box value={value} />

        <S.Label mode={mode}>{label}</S.Label>
      </S.Container>
    </BaseInput>
  );
};

export default BoxInput;
