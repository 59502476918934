import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Body, Header, Button, Loader, FormGrid, FormConfig, TextValueHeader, TextValue } from 'components';
import { useService } from 'hooks';

const LIST_STARTUPS = '/admin/startups';

const List: React.FC = () => {
  const params = useParams<{ startup?: string }>();
  const [searchParams] = useSearchParams();

  const startupService = useService('get', `/startup/${params.startup}`);

  const startup = React.useMemo(() => startupService.data?.data?.startup, [startupService.data]);

  const dataConfig: FormConfig = [
    [
      {
        schema: 'header-startup',
        size: { md: 12 },
        type: TextValueHeader,
        props: () => ({
          label: 'Detalhes',
        }),
      },
    ],
    [
      {
        schema: 'name',
        size: { md: 9 },
        type: TextValue,
        props: (schema) => ({
          label: 'Nome',
          value: startup?.name,
          mode: 'dark',
        }),
      },
      {
        schema: 'foundationYear',
        size: { md: 3 },
        type: TextValue,
        props: (schema) => ({
          label: 'Ano de fundação',
          value: startup?.foundationYear,
          mask: 'numeric',
        }),
      },
      {
        schema: 'website',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Site',
          value: startup?.website,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'city',
        size: { md: 6 },
        type: TextValue,
        props: (schema) => ({
          label: 'Em qual cidade estão sediados?',
          value: startup?.city,
          mode: 'dark',
        }),
      },
      {
        schema: 'state',
        size: { md: 6 },
        type: TextValue,
        props: (schema) => ({
          label: 'e o estado?',
          value: startup?.state?.name,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'responsible.name',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Nome do CEO',
          value: startup?.responsible?.name,
          isRequired: true,
          mode: 'dark',
        }),
      },
      {
        schema: 'responsible.linkedin',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Linkedin do CEO',
          value: startup?.responsible?.linkedin,
          mode: 'dark',
        }),
      },
      {
        schema: 'responsible.email',
        size: { md: 6 },
        type: TextValue,
        props: (schema) => ({
          label: 'E-mail do CEO',
          value: startup?.responsible?.email,
          mode: 'dark',
        }),
      },

      {
        schema: 'responsible.phone',
        size: { md: 6 },
        type: TextValue,
        props: (schema) => ({
          label: 'Whatsapp do CEO',
          value: startup?.responsible?.phone,
          mode: 'dark',
          mask: 'phone',
        }),
      },
    ],
    [
      {
        schema: 'stage',
        size: { md: 6 },
        type: TextValue,
        props: (schema) => ({
          label: 'Estágio',
          value: startup?.stage?.name,
          mode: 'dark',
        }),
      },
      {
        schema: 'activity',
        size: { md: 6 },
        type: TextValue,
        props: (schema) => ({
          label: 'Setor de atuação',
          value: startup?.activity?.name,
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],

    [
      {
        schema: 'header-responsible',
        size: { md: 12 },
        type: TextValueHeader,
        props: () => ({
          label: 'Informações Gerais',
        }),
      },
    ],

    [
      {
        schema: 'valueOffer',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual a proposta de valor da startup para seus clientes?',
          value: startup?.infoProfile?.valueOffer,
          mode: 'dark',
        }),
      },
      {
        schema: 'companyGoal',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual o problema que resolvem e como fazem isso?',
          value: startup?.infoProfile?.companyGoal,
          mode: 'dark',
        }),
      },
      {
        schema: 'target',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual seu público-alvo?',
          value: startup?.infoProfile?.target?.name,
          mode: 'dark',
        }),
      },
      {
        schema: 'businessModel',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'E modelo de negócio?',
          value: startup?.infoProfile?.businessModel?.name,
          mode: 'dark',
        }),
      },
      {
        schema: 'activeClients',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Quantos clientes ativos possuem?',
          value: startup?.infoProfile?.activeClients,
          mask: 'numeric',
        }),
      },
      {
        schema: 'cnpj',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual o CNPJ da startup?',
          value: startup?.infoProfile?.cnpj,
          mode: 'dark',
          mask: 'cnpj',
        }),
      },
      {
        schema: 'linkedin',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual o LinkedIn da startup?',
          value: startup?.infoProfile?.linkedin,
          mode: 'dark',
        }),
      },
      {
        schema: 'instagram',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual o Instagram da startup? ',
          value: startup?.infoProfile?.instagram,
          mode: 'dark',
        }),
      },
      {
        schema: 'numberOfCollaborators',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Quantos funcionários possuem atualmente?',
          value: startup?.infoProfile?.numberOfCollaborators,
          mode: 'dark',
        }),
      },

      {
        schema: 'investmentType',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'A startup já recebeu investimentos?',
          value: startup?.infoProfile?.investmentType?.name,
          mode: 'dark',
        }),
      },
      {
        schema: 'pitchdeck',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Pitchdeck',
          value: startup?.infoProfile?.pitchdeck,
          mode: 'dark',
        }),
      },
      {
        schema: 'pitchUrl',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Vídeo pitch',
          value: startup?.infoProfile?.pitchUrl,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.participate',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'A startup participa/participou de algum programa de aceleração?',
          value: startup?.infoProfile?.accelerationProgram?.participate ? 'Sim' : 'Não',
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.which',
        size: { md: 12 },
        type: TextValue,
        hide: () => !startup?.infoProfile?.accelerationProgram?.participate,
        props: (schema) => ({
          label: 'Qual?',
          value: startup?.infoProfile?.accelerationProgram?.which,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.when',
        size: { md: 12 },
        type: TextValue,
        hide: () => !startup?.infoProfile?.accelerationProgram?.participate,
        props: (schema) => ({
          label: 'Quando?',
          value: startup?.infoProfile?.accelerationProgram?.when,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.step',
        size: { md: 12 },
        type: TextValue,
        hide: () => !startup?.infoProfile?.accelerationProgram?.participate,
        props: (schema) => ({
          label: 'Quais contrapartidas?',
          value: startup?.infoProfile?.accelerationProgram?.step?.name,
          mode: 'dark',
        }),
      },
      {
        schema: 'accelerationProgram.amount',
        size: { md: 12 },
        type: TextValue,
        hide: () => !startup?.infoProfile?.accelerationProgram?.participate,
        props: (schema) => ({
          label: 'Quanto?',
          value: startup?.infoProfile?.accelerationProgram?.amount,
          mode: 'dark',
          // mask: 'money',
        }),
      },
    ],

    [
      {
        schema: 'header-responsible',
        size: { md: 12 },
        type: TextValueHeader,
        props: () => ({
          label: 'Informações para Investimento',
        }),
      },
    ],

    [
      {
        schema: 'interestedInInvesting',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'A startup está buscando investimentos?',
          value: startup?.investorProfile?.interestedInInvesting ? 'Sim' : 'Não',
          mode: 'dark',
        }),
      },
      {
        schema: 'interestValue',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual o valor da rodada que buscam?',
          value: startup?.investorProfile?.interestValue,
          mode: 'dark',
          mask: 'money',
        }),
      },
      {
        schema: 'percentageOffered',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Por qual porcentagem da empresa?',
          value: startup?.investorProfile?.percentageOffered,
          mode: 'dark',
          mask: 'percent',
        }),
      },
    ],
    [
      {
        schema: 'founders',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Descreva o time de founders',
          value: startup?.investorProfile?.founders,
          mode: 'dark',
        }),
      },
      {
        schema: 'annualGrossIncome',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual a receita bruta que teve somada nos últimos 12 meses? (ou ARR) ',
          value: startup?.investorProfile?.annualGrossIncome,
          mode: 'dark',
          mask: 'money',
        }),
      },
    ],
    [
      {
        schema: 'breakeven',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'A startup já atingiu o ponto de equilíbrio – Breakeven?',
          value: startup?.investorProfile?.breakeven,
          mode: 'dark',
        }),
      },
      {
        schema: 'grewBillingLastYear',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Quanto cresceu o faturamento nos últimos 12 meses? ',
          value: startup?.investorProfile?.grewBillingLastYear,
          mode: 'dark',
          mask: 'money',
        }),
      },
      {
        schema: 'cac',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual seu CAC (Custo de Aquisição por cliente)?',
          value: startup?.investorProfile?.cac,
          mask: 'money',
        }),
      },

      {
        schema: 'ltv',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual seu LTV (life time value)?',
          value: startup?.investorProfile?.ltv,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'profitMargin',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual sua margem média mensal neste período?',
          value: startup?.investorProfile?.profitMargin,
          mode: 'dark',
          mask: 'money',
        }),
      },
      {
        schema: 'salesCycle',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Ciclo de vendas (Tempo para fazer uma venda)?',
          value: startup?.investorProfile?.salesCycle,
          mode: 'dark',
        }),
      },
      {
        schema: 'churn',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual sua taxa de churn?',
          value: startup?.investorProfile?.churn,
          mode: 'dark',
        }),
      },
      {
        schema: 'marketSize',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual tamanho estimado do mercado?',
          value: startup?.investorProfile?.marketSize,
          mode: 'dark',
        }),
      },
      {
        schema: 'percentageMarketSizeWantServe',
        size: { md: 12 },
        type: TextValue,
        props: (schema) => ({
          label: 'Qual a porcentagem desse mercado que buscam atender?',
          value: startup?.investorProfile?.percentageMarketSizeWantServe,
          mode: 'dark',
          mask: 'percent',
        }),
      },
    ],

    [
      {
        schema: 'header-challenges',
        size: { md: 12 },
        type: TextValueHeader,
        props: () => ({
          label: 'Desafios',
        }),
      },
    ],
    ...(startup?.challenges || [])?.map((item) => [
      {
        schema: `challenges.${item._id}.challenge.name`,
        size: { md: 9 },
        type: TextValue,
        props: () => ({
          label: 'Desafio',
          value: item.challenge?.name,
        }),
      },
      {
        schema: `challenges.${item._id}.challenge.createdAt`,
        size: { md: 3 },
        type: TextValue,
        props: () => ({
          label: 'Data inscrição',
          value: item.challenge?.createdAt,
          mask: 'date',
        }),
      },
    ]),
  ];

  return (
    <Body>
      <Loader show={startupService.loading}>
        <Header
          title="Dados da startup"
          left={
            <Button variant="outlined" to={`${LIST_STARTUPS}?${searchParams.toString()}`}>
              Voltar
            </Button>
          }
        />

        <FormGrid config={dataConfig} />
      </Loader>
    </Body>
  );
};

export default List;
