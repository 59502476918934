import React from 'react';
import { Outlet } from 'react-router-dom';

import Menu from './components/Menu';
import * as S from './styled';

const Platform: React.FC = () => {
  return (
    <S.Container>
      <Menu />

      <S.Content>
        <S.Body>
          <Outlet />
        </S.Body>
      </S.Content>
    </S.Container>
  );
};

export default Platform;
