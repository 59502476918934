import styled, { DefaultTheme } from 'styled-components';

export const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
    error: {
      color: '#fff',
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
    error: {
      color: theme.palette.error.main,
    },
  },
});
export const Container = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: 100%;
`;

export const Error = styled.span.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  color: ${({ mode, modes }) => modes[mode].error.color};
  font-size: 12px;
  margin-top: 2px;
`;
