import React from 'react';
import * as R from 'ramda';

import { ReactComponent as BrandSVG } from 'assets/images/color-icon.svg';
import { ReactComponent as RocketAvatarSVG } from 'assets/images/rocket-avatar.svg';

import { IAuthState } from 'types/contexts/auth';
import { Icon, IconEnum } from 'components';
import { AuthContext } from 'contexts';
import { useState } from 'hooks';

import * as S from './styled';

const menus: Array<{
  label: string;
  icon: IconEnum;
  link: string | ((data: IAuthState) => string);
  hide?: (data: IAuthState) => boolean;
}> = [
  // {
  //   label: 'Home',
  //   icon: 'home',
  //   link: '/startup',
  // },
  // {
  //   label: 'My Courses',
  //   icon: 'paper-airplane',
  //   link: '/startup/courses/my',
  // },
  // {
  //   label: 'Brouse Courses',
  //   icon: 'book-search',
  //   link: '/startup/courses',
  // },
  {
    label: 'My Startup',
    icon: 'book-search',
    link: '/startup/my-startup/',
  },
  {
    label: 'Startup Start',
    icon: 'book',
    link: '/startup/startup-start',
  },
  // {
  //   label: 'Startup Dashboard',
  //   icon: 'rocket',
  //   link: '/startup/dashboard',
  // },
  {
    label: 'Matchmaking',
    icon: 'puzzle',
    link: '/startup/match-making',
  },
  {
    label: 'Mentoria',
    icon: 'people',
    link: (data: IAuthState) => `/startup/mentorship/${data.startup?.mentorship}`,
    hide: (data: IAuthState) => !data.startup?.mentorship,
  },
  {
    label: 'Integrações',
    icon: 'gear',
    link: '/startup/integrations',
  },
  // {
  //   label: 'Logout',
  //   icon: 'logout',
  //   link: '/logout',
  // },
];

const Menu: React.FC = () => {
  const { state: authState } = AuthContext.useAuth();
  const [state, setState] = useState({ open: false });

  const toggleMenu = () => {
    setState({ open: !state.open });
  };

  const menusToShow = React.useMemo(() => {
    return menus
      .filter((menu) => !menu.hide || !menu?.hide(authState))
      .map((menu) => ({
        ...menu,
        link: typeof menu.link === 'function' ? menu.link(authState) : menu.link,
      }));
  }, [menus, authState]);

  return (
    <S.Container isOpen={state.open}>
      <S.LogoContent>
        <S.Logo as={BrandSVG} onClick={toggleMenu} />
      </S.LogoContent>

      <S.StartupContent>
        <S.StartupAvatar as={RocketAvatarSVG} />

        <S.StartupDetails>
          <S.StartupName>{authState.startup?.name}</S.StartupName>

          <S.StartupDate>{authState.user?.name}</S.StartupDate>
        </S.StartupDetails>
      </S.StartupContent>

      <S.Options>
        {menusToShow.map((menu) => (
          <S.Option to={menu.link} key={menu.link} onClick={toggleMenu}>
            <S.OptionIcon as={Icon} name={menu.icon} color="black" />

            <S.OptionLabel>{menu.label}</S.OptionLabel>
          </S.Option>
        ))}
      </S.Options>

      <S.Footer>
        {/*
        <S.RequestInvestment>
          <S.RequestInvestmentLabel>Quero Investimento!</S.RequestInvestmentLabel>

          <S.RequestInvestmentIcon as={Icon} name="rocket" color="white" />
        </S.RequestInvestment>
      */}

        <S.Options>
          <S.Option to="/logout" key="logout" noBorder onClick={toggleMenu}>
            <S.OptionIcon as={Icon} name="logout" color="black" />

            <S.OptionLabel>Logout</S.OptionLabel>
          </S.Option>
        </S.Options>
      </S.Footer>
    </S.Container>
  );
};

export default Menu;
