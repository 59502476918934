import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, TableList, Header, Button, TableListButtons, TableListButtonsProps } from 'components';
import { useService } from 'hooks';

const PAGE_KEY = 'admin_page';

const List: React.FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/mentorship`, { page: currentPage }, true, [currentPage]);
  const removeService = useService('delete', (data) => `/mentorship/${data?.id}`, {}, false);

  const handleOnRemove = async (row: any) => {
    const response = await removeService.fetch({}, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Aula salva com sucesso');
      return listService.fetch();
    }

    return toast('Houve um erro ao remover', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: 'trash',
      label: 'Excluir',
      onClick: handleOnRemove,
    },
  ];

  const tableListConfig = [
    {
      title: 'Startup',
      size: {
        md: 5,
      },
      path: 'startup.name',
    },
    {
      title: 'Orientador',
      size: {
        md: 5,
      },
      path: 'advisor.name',
    },

    {
      title: '',
      size: {
        md: 2,
      },
      path: (row: any) => <TableListButtons data={row} config={tableListButtonsConfig} />,
    },
  ];

  return (
    <>
      <Header title="Mentorias" right={<Button to={`${location.pathname}/create`}>Adicionar mentoria</Button>} />

      <TableList
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        dataPath="mentorships.items"
        paginate
        paginatePath="mentorships"
        onChangePage={handleOnChangePage}
      />
    </>
  );
};

export default List;
