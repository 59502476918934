import styled, { css } from 'styled-components';

import * as Types from './types';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        padding: 12px;
      `,
      1
    )}
`;

export const Content = styled.div<{
  background: Types.Color;
  opacity?: number;
  noPadding: boolean;
  radius: number;
  padding: number;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${({ background, theme }) => theme.helpers.getColor(background)};
  padding: ${({ noPadding, padding }) => (noPadding ? `0 ${padding}px` : padding)}px;
  border-radius: ${({ radius }) => radius}px;
  /* box-shadow: 2px 2px 10px 1px #0004; */
`;
