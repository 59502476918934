export const onlyNumbers = (data: string, number?: boolean): string | number => {
  const result = `${data || ''}`?.replace(/[^0-9]/g, '');

  if (number) return parseInt(result, 10);

  return result;
};

export const stringToDecimal = (data: string) => {
  if (!data) return 0;

  const number = parseFloat(data.replace('R$', '').replace(/\./g, '').replace(',', '.'));

  return number;
};

export const decimalToString = (data: number) => {
  if (!data) return 0;

  const number = `${data}`.replace('.', ',');

  return number;
};

export const formatMoney = (value: number | string) => {
  const newValue = typeof value === 'number' ? value : parseInt(value)
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(newValue.toFixed(2)));
};

export const clearFormatMoney = (value: string): number => {
  return Number(value?.substring(3).replace('.', '').replace(',', '.') || 0);
};

export const random = (min: number = 0, max: number = 100) => {
  return Math.random() * (max - min) + min;
};
