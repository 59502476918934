import * as S from './styled';

interface BaseInputProps {
  error?: string;
  children: any;
  mode?: 'light' | 'dark';
  direction?: 'row' | 'column';
}

const BaseInput: React.FC<BaseInputProps> = ({ children, error, mode = 'light', direction = 'column' }) => {
  return (
    <S.Container direction={direction}>
      {children}

      <S.Error mode={mode}>{error}</S.Error>
    </S.Container>
  );
};

export default BaseInput;
