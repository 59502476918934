import React from 'react';

import { Header } from 'components';

import * as S from './styled';

const Schedules: React.FC = () => {
  return (
    <>
      <Header title="Agendamentos" />

      <S.Container>
        <S.Content>{/* <Body></Body> */}</S.Content>
      </S.Container>
    </>
  );
};

export default Schedules;
