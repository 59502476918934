import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Loader, Icon } from 'components';
import { useService } from 'hooks';

import * as S from './styled';

const Class: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const classesService = useService('get', '/startup-start');
  const myClassesService = useService('get', `/startup/my-startup/startup-start`);
  const updateMyClassService = useService('post', (data) => `/startup/my-startup/startup-start/${data._id}`, {}, false);

  const handleOnUpdate =
    (field: string, value: boolean, item: { _id: string }) => async (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const data = {
        [field]: value,
      };

      const response = await updateMyClassService.fetch(data, { _id: item._id });

      if (response?.data?.status === 'OK') {
        toast('Atualizado com sucesso');
        await myClassesService.fetch();
        return;
      }

      return toast('Houve um erro ao atualizar', { type: 'error' });
    };

  const handleOnSelectClass = (row) => () => {
    navigate(`${location.pathname}/${row._id}`);
  };

  const classes = classesService?.data?.data?.startupStarts?.items || [];
  const myClasses = myClassesService?.data?.data?.startupStarts?.items || [];

  return (
    <Loader show={classesService.loading}>
      <S.Container>
        <S.Classes>
          {classes.map((item) => {
            const hasClasses = myClasses.find((myClass) => myClass.startupStart._id === item._id);

            return (
              <S.Class key={item._id} onClick={handleOnSelectClass(item)}>
                <S.Image src={item.image.url} />

                <S.ClassContent>
                  <S.Title>{item.name}</S.Title>

                  <S.Description>{item.description}</S.Description>

                  <S.Details>
                    <S.Detail>
                      <S.DetailTitle>
                        <Icon name="stopwatch" size={14} color="disabled" />
                        Duração
                      </S.DetailTitle>

                      <S.DetailValue>{Math.trunc(item.time / 60)} min</S.DetailValue>
                    </S.Detail>

                    <S.Detail>
                      <S.DetailTitle>
                        <Icon name="profile" size={14} color="disabled" />
                        Autor
                      </S.DetailTitle>

                      <S.DetailValue>{item.author}</S.DetailValue>
                    </S.Detail>

                    <S.Detail>
                      <S.DetailTitle>
                        <Icon name="check" size={14} color="disabled" />
                        Status
                      </S.DetailTitle>

                      <S.DetailValue>{hasClasses?.viewed ? 'Visto' : 'Não visto'}</S.DetailValue>
                    </S.Detail>
                  </S.Details>
                </S.ClassContent>

                <S.FavoriteContent>
                  <Icon
                    onClick={handleOnUpdate('favorite', !hasClasses?.favorite, item)}
                    name={hasClasses?.favorite ? 'bookmark-fill' : 'bookmark'}
                    color="black"
                    size={24}
                  />
                </S.FavoriteContent>
              </S.Class>
            );
          })}
        </S.Classes>
      </S.Container>
    </Loader>
  );
};

export default Class;
