import React from 'react';
import { useLocation, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, TableList, Header, Button, TableListButtons, TableListButtonsProps } from 'components';
import { useService } from 'hooks';
import { dateToString } from 'utils/date';

const LIST_COURSES = '/admin/courses';
const PAGE_KEY = 'class_page';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ course: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/course/${params.course}/class`, { page: currentPage }, true, [currentPage]);
  const removeService = useService('delete', (data) => `/course/${params.course}/class/${data.id}`, {});

  const handleOnSelect = (row: any) => {};

  const handleOnEdit = (row: any) => {
    navigate({ pathname: `${location.pathname}/edit/${row._id}`, search: searchParams.toString() });
  };

  const handleOnRemove = async (row: any) => {
    const response = await removeService.fetch({}, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Aula salva com sucesso');
      return listService.fetch();
    }

    return toast('Houve um erro ao remover', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: 'pencil',
      label: 'Editar',
      onClick: handleOnEdit,
    },
    {
      icon: 'trash',
      label: 'Excluir',
      onClick: handleOnRemove,
    },
  ];

  const tableListConfig = [
    {
      title: 'Nome',
      size: {
        md: 8,
      },
      path: 'name',
    },
    {
      title: 'Duração',
      size: {
        md: 2,
      },
      path: (row: any) => dateToString(row.time, 'HH:mm:ss'),
    },
    {
      title: '',
      size: {
        md: 2,
      },
      path: (row: any) => <TableListButtons data={row} config={tableListButtonsConfig} />,
    },
  ];

  return (
    <Body>
      <Header
        title="Aulas"
        left={
          <Button variant="outlined" to={`${LIST_COURSES}?${searchParams.toString()}`}>
            Cursos
          </Button>
        }
        right={<Button to={`${location.pathname}/create?${searchParams.toString()}`}>Nova aula</Button>}
      />

      <TableList
        onRowClick={handleOnSelect}
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        dataPath="classes.items"
        paginate
        paginatePath="classes"
        onChangePage={handleOnChangePage}
      />
    </Body>
  );
};

export default List;
