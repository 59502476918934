import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 80vh;
`;
