import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, Header, Button, Loader, Icon } from 'components';
import { useService } from 'hooks';

import * as S from './styled';

const Class: React.FC = () => {
  const params = useParams<{ class: string }>();
  const classService = useService('get', `/startup-start/${params.class}`);
  const myClassService = useService('get', `/startup/my-startup/startup-start/${params.class}`);
  const updateMyClassService = useService('post', `/startup/my-startup/startup-start/${params.class}`, {}, false);

  const handleOnUpdate = (field: string, value: boolean) => async () => {
    const data = {
      [field]: value,
    };

    const response = await updateMyClassService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Atualizado com sucesso');
      await myClassService.fetch();
      return;
    }

    return toast('Houve um erro ao atualizar', { type: 'error' });
  };

  const data = classService?.data?.data?.startupStart || {};
  const myClass = myClassService?.data?.data?.startupStart || {};

  if (!data?._id && !myClass?._id) return <Loader />;

  return (
    <div>
      <Header
        title={data.name || ''}
        left={
          <Button to="/startup/startup-start" variant="outlined">
            Voltar
          </Button>
        }
      />

      <Body>
        <S.Banner />

        <S.Video dangerouslySetInnerHTML={{ __html: data?.videoUrl }} />

        <S.Details>
          <S.Detail>
            <S.DetailTitle>
              <Icon name="stopwatch" size={14} color="disabled" />
              Duração
            </S.DetailTitle>

            <S.DetailValue>{Math.trunc(data.time / 60)} min</S.DetailValue>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>
              <Icon name="profile" size={14} color="disabled" />
              Autor
            </S.DetailTitle>

            <S.DetailValue>{data.author}</S.DetailValue>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>
              <Icon name="check" size={14} color="disabled" />
              Status
            </S.DetailTitle>

            <S.DetailButton disabled={updateMyClassService.loading} onClick={handleOnUpdate('viewed', !myClass.viewed)}>
              {myClass.viewed ? 'definir não visualizado' : 'definir visualizado'}
            </S.DetailButton>
          </S.Detail>

          <S.Detail>
            <S.DetailTitle>
              <Icon name={myClass.favorite ? 'bookmark-fill' : 'bookmark'} size={14} color="disabled" />
              Favorito
            </S.DetailTitle>

            <S.DetailButton
              disabled={updateMyClassService.loading}
              onClick={handleOnUpdate('favorite', !myClass.favorite)}>
              {myClass.favorite ? 'remover favorito' : 'favoritar'}
            </S.DetailButton>
          </S.Detail>
        </S.Details>

        <S.DescriptionLabel>Descrição</S.DescriptionLabel>

        <S.Description>{data.description}</S.Description>
      </Body>
    </div>
  );
};

export default Class;
