import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 190px;
  background: #f8f4f4;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 48px;
  padding: 24px 16px;

  margin: 24px 12px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Logo = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

export const StartupName = styled.span`
  font-weight: 600;
  font-size: 14px;
  margin-left: 8px;
`;

export const StartupResume = styled.span`
  font-weight: normal;
  margin-top: 24px;
  font-size: 10px;
  flex-wrap: wrap;
  line-height: 14px;
`;

export const Advisor = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 22px;
  object-fit: cover;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdvisorTitle = styled.span`
  font-size: 10px;
  line-height: 16px;
`;

export const AdvisorName = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
`;
