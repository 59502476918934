import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import useService from 'hooks/service';
import { Loader } from 'components';

import StartupCard from '../StartupCard';
import * as S from './styled';

const Startups: React.FC = () => {
  const navigate = useNavigate();

  const startupsService = useService('get', `/mentorship`, { limit: 10 }, true);

  const handleOnSelect = (mentorship: { _id: string }) => {
    navigate(`/advisor/mentorship/${mentorship._id}`);
  };

  return (
    <S.Container>
      <S.Title>Startups selecionadas</S.Title>

      <Loader show={startupsService.loading}>
        <S.Content>
          <Slider
            dots={true}
            infinite={false}
            variableWidth={true}
            slideToScroll={1}
            centerMode={false}
            arrows={false}>
            {startupsService.data?.data?.mentorships?.items.map((item) => (
              <StartupCard data={item} key={item._id} onClick={() => handleOnSelect(item)} />
            ))}
          </Slider>
        </S.Content>
      </Loader>
    </S.Container>
  );
};

export default Startups;
