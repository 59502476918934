import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Tabs } from 'components';

import Availables from './Availables';
import Orienteds from './Orienteds';

const TabsComponent: React.FC = () => {
  const params = useParams<{ schema: string }>();
  const navigate = useNavigate();

  const handleOnChange = (schema: string) => {
    navigate(`/admin/mentorships/${schema}`);
  };

  const tabsConfig = [
    {
      schema: 'orienteds',
      label: 'Mentorias',
      node: Orienteds,
    },
    {
      schema: 'availables',
      label: 'Startups disponiveis',
      node: Availables,
    },
  ];

  return <Tabs config={tabsConfig} current={params.schema} onChange={handleOnChange} />;
};

export default TabsComponent;
