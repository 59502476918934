import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: #edf1f6;
  padding: 24px;
  margin-top: 48px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const NewComment = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 12px;
  gap: 20px;
`;

export const Empty = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: #b5b7c0;
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #67727e88;
  padding: 24px;
`;

export const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const UserLogo = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  object-fit: cover;
`;

export const UserName = styled.span`
  font-size: 14px;
  color: #334253;
  margin-left: 16px;
`;

export const Date = styled.span`
  font-size: 14px;
  color: #67727e;
  margin-left: auto;
`;

export const Reply = styled.span`
  font-size: 14px;
  color: #5357b6;
  margin-left: 16px;
`;

export const Message = styled.div`
  font-size: 14px;
  color: #67727e;
  margin-top: 16px;
`;
