export const getBase64File = (file: File) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve({
        base64: reader.result,
        path: URL.createObjectURL(file),
      });
    };

    reader.onerror = function (error) {
      reject();
    };
  });
};
