import React from 'react';
import ReactTooltip from 'react-tooltip';

import BaseInput from '../BaseInput';
import Icon from '../Icon';
import * as S from './styled';

interface TextFieldProps extends Omit<React.HTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  label: string;
  legend?: string;
  tooltip?: string;
  rows?: number;
  value?: string | number;
  onChange: (value?: string) => void;
  isRequired?: boolean;
  error?: string;
  disabled?: boolean;
  mode: 'light' | 'dark';
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  legend,
  tooltip,
  value,
  onChange,
  isRequired,
  error,
  rows = 3,
  disabled = false,
  mode = 'light',
  ...props
}) => {
  const id = React.useId();
  const textFieldId = `text-field-${id}`;

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;

    onChange?.(value);
  };

  return (
    <BaseInput error={error}  mode={mode}>
      <S.Container>
        <S.Label mode={mode}>
          {label}

          {isRequired ? ' *' : ''}
        </S.Label>

        <S.Legend mode={mode}>{legend}</S.Legend>

        <S.InputContent>
          <S.Input
            rows={rows}
            mode={mode}
            disabled={disabled}
            onChange={handleOnChange}
            value={value || ''}
            {...props}
          />

          {!tooltip ? null : (
            <S.Tooltip>
              <S.TooltipIcon data-tip data-for={textFieldId}>
                <Icon name="info-circle" size={18} />
              </S.TooltipIcon>

              <ReactTooltip id={textFieldId} place="top" type="dark" effect="solid">
                <span>{tooltip}</span>
              </ReactTooltip>
            </S.Tooltip>
          )}
        </S.InputContent>
      </S.Container>
    </BaseInput>
  );
};

export default TextField;
