import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        flex-direction: column;
      `,
      1
    )}
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  background: ${({ theme }) => theme.palette.gradient.main};
  padding: 20px;
  overflow: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  box-shadow: 2px 2px 10px 1px #0004;
  width: calc(100% - 300px);

  ${({ theme }) =>
    theme.screens.xs(
      css`
        width: 100%;
      `,
      1
    )}
`;
