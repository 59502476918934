import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  padding: 0 20px;
  overflow: auto;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 40px 0;
`;

export const UserName = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
  cursor: pointer;
`;

export const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;
