import styled, { css } from 'styled-components';

export const Challenges = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;
  justify-content: space-between;
  transition: 0.4s;
`;

export const Challenge = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 28px 28px;
  width: 300px;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        width: 100%;
      `,
      1
    )}
`;

export const ChallengeContent = styled.div`
  position: relative;
  background: #fff;
  border-radius: 16px;
  padding: 32px 20px;
  margin-top: 14px;
`;

export const Image = styled.span`
  position: absolute;
  height: 60px;
  top: -34px;
  left: 20px;
`;

export const Text = styled.span`
  display: block;
  font-size: 14px;
  text-align: center;

  & + & {
    margin-top: 18px;
  }
`;

export const Title = styled.span`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  height: 58px;
`;

export const KnowMore = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.colors.purple.main};
  cursor: pointer;
  margin-top: 18px;
`;

export const Register = styled.div<{ disabled: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 32px);
  height: 26px;
  border-radius: 13px;
  background: ${({ theme, disabled }) => (disabled ? theme.palette.disabled.main : theme.palette.primary.main)};
  color: ${({ theme, disabled }) => (disabled ? theme.palette.disabled.text : theme.palette.primary.text)};
  top: calc(100% - 13px);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const RegisterLabel = styled.span`
  font-size: 12px;
  font-weight: bold;
`;
