import styled from 'styled-components';

export const Container = styled.div`
  .react-calendar {
    border: none;
    background: #fff;
    border-radius: 20px;
    padding: 8px;
    padding-top: 0px;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }

  .react-calendar__navigation {
    height: 72px;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ececed;
  }

  .react-calendar__navigation button:hover,
  .react-calendar__navigation button:focus {
    background: none;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #ececed;
    font-size: 18px;
  }

  .react-calendar__month-view__weekdays {
    color: #8d8a95;
    font-size: 12px;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }

  .react-calendar__month-view__days button,
  .react-calendar__month-view__days button:hover,
  .react-calendar__month-view__days button:focus  {
    display: flex;
    justify-content: center;
    background: none;
    align-items: center;
    max-width: calc(100% / 7);
    height: 36px;
    padding: 0;
  }

  .react-calendar__tile--now {
    background: none;
    color: inherit;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:hover,
  .react-calendar__tile--active:focus {
    background: none;
    color: inherit;
  }

  .react-calendar__tile--active > abbr,
  .react-calendar__tile--active:focus > abbr {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.text};
    width: 32px;
    height: 32px;
  }

  .react-calendar__tile:hover > abbr {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    color: inherit;
    width: 32px;
    height: 32px;
  }
`;
