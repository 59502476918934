import React from 'react';

import * as S from './styled';

interface StartupCardProps {
  onClick: () => void;
  data: {
    advisor: {
      name;
    };
    startup: {
      name;
    };
  };
}

const StartupCard: React.FC<StartupCardProps> = ({ data, onClick }) => {
  const logo = 'https://cdn1.iconfinder.com/data/icons/developer-set-2/512/users-512.png';
  const avatar =
    'https://w7.pngwing.com/pngs/1008/377/png-transparent-computer-icons-avatar-user-profile-avatar-heroes-black-hair-computer.png';

  return (
    <S.Card onClick={onClick}>
      <S.Header>
        <S.Logo src={logo} />

        <S.StartupName>{data.startup.name}</S.StartupName>
      </S.Header>

      <S.StartupResume>
        The leading international wealth-tax FinTech platform for the fastest way to reclaim foreign dividend
        withholding taxes
      </S.StartupResume>

      <S.Advisor>
        <S.Avatar src={avatar} />

        <S.Profile>
          <S.AdvisorTitle>Orientador</S.AdvisorTitle>

          <S.AdvisorName>{data.advisor.name}</S.AdvisorName>
        </S.Profile>
      </S.Advisor>
    </S.Card>
  );
};

export default StartupCard;
