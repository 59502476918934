import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Modal, Button, FormGrid, FormConfig, TextValue } from 'components';
import { useService } from 'hooks';
import { dateToString } from 'utils/date';

interface NewFeedbackProps {
  onClose: () => void;
  feedback: string;
}

const NewFeedback: React.FC<NewFeedbackProps> = ({ feedback, onClose }) => {
  const params = useParams();

  const getService = useService('get', `mentorship/${params.mentorship}/feedback/${feedback}`);

  const data = getService?.data?.data?.feedback;

  const formConfig: FormConfig = [
    [
      {
        schema: 'advisor',
        size: { md: 8 },
        type: TextValue,
        props: () => ({
          label: 'Mentor',
          value: data?.advisor?.name,
        }),
      },
      {
        schema: 'createdAt',
        size: { md: 4 },
        type: TextValue,
        props: () => ({
          label: 'Data',
          value: dateToString(data?.createdAt, 'dd/MM/yyyy HH:mm'),
        }),
      },
    ],
    [
      {
        schema: 'descrption',
        size: { md: 12 },
        type: TextValue,
        props: () => ({
          value: <pre>{data?.description}</pre>,
        }),
      },
    ],
  ];
  return (
    <Modal
      size={{ md: 6 }}
      title="Pendência"
      loading={getService.loading}
      Footer={
        <>
          <Button variant="outlined" onClick={onClose}>
            Voltar
          </Button>
        </>
      }>
      <FormGrid config={formConfig} />
    </Modal>
  );
};

export default NewFeedback;
