import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Body, TableList, Header, Button, TableListButtons, TableListButtonsProps } from 'components';
import { useService } from 'hooks';
import { secondsToString } from 'utils/date';

const PAGE_KEY = 'startup_start_page';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/startup-start`, { page: currentPage }, true, [currentPage]);
  const removeService = useService('delete', (data) => `/startup-start/${data?.id}`, {}, false);

  const handleOnEdit = (row: any) => {
    navigate(`${location.pathname}/edit/${row._id}`);
  };

  const handleOnRemove = async (row: any) => {
    const response = await removeService.fetch({}, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Aula salva com sucesso');
      return listService.fetch();
    }

    return toast('Houve um erro ao remover', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: 'pencil',
      label: 'Editar',
      onClick: handleOnEdit,
    },
    {
      icon: 'trash',
      label: 'Excluir',
      onClick: handleOnRemove,
    },
  ];

  const tableListConfig = [
    {
      title: 'Nome',
      size: {
        md: 8,
      },
      path: 'name',
    },
    {
      title: 'Duração',
      size: {
        md: 2,
      },
      path: (row: any) => secondsToString(row.time),
    },
    {
      title: '',
      size: {
        md: 2,
      },
      path: (row: any) => <TableListButtons data={row} config={tableListButtonsConfig} />,
    },
  ];

  return (
    <Body>
      <Header title="Aulas" right={<Button to={`${location.pathname}/create`}>Nova aula</Button>} />

      <TableList
        config={tableListConfig}
        loading={listService.loading}
        data={listService?.data?.data}
        dataPath="startupStarts.items"
        paginate
        paginatePath="startupStarts"
        onChangePage={handleOnChangePage}
      />
    </Body>
  );
};

export default List;
