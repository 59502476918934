import React from 'react';
import { ScreensType } from 'types/styled-components';

import * as S from './styled';

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  spacing?: number;
  size?: Partial<ScreensType>;
  container?: boolean;
  children: any;
  direction?: 'row' | 'column';
  align?: 'flex-end' | 'flex-start' | 'center';
}

const Grid: React.FC<GridProps> = ({
  container = false,
  spacing = 12,
  direction = 'row',
  size = { md: 12 },
  children,
  align,
  ...props
}) => {
  if (container)
    return (
      <S.Container spacing={spacing} {...props}>
        {children}
      </S.Container>
    );

  return (
    <S.Content spacing={spacing} direction={direction} size={size as ScreensType} align={align} {...props}>
      {children}
    </S.Content>
  );
};

export default Grid;
