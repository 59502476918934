import React from 'react';
import { Routes, Route } from 'react-router-dom';

import List from './list';
import Startup from './startup';

const Courses: React.FC = () => {
  return (
    <Routes>
      {/* /admin/startups */}
      <Route path="/" element={<List />} />
      <Route path="/:startup/*" element={<Startup />} />

    </Routes>
  );
};

export default Courses;
