import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Mentorship as MentorshipPage } from 'pages/common';
import { Body } from 'components';

const Mentorship: React.FC = () => {
  return (
    <Routes>
      {/* /advisor/mentorship */}
      <Route
        path="/:mentorship"
        element={
          <Body>
            <MentorshipPage />
          </Body>
        }
      />
    </Routes>
  );
};

export default Mentorship;
