import { ReactComponent as BrandSVG } from 'assets/images/color-icon.svg';
import { Icon, IconEnum } from 'components';

import * as S from './styled';

const menus: Array<{
  label: string;
  icon: IconEnum;
  link: string;
}> = [
  {
    label: 'Home',
    icon: 'home',
    link: '/admin/',
  },
  {
    label: 'Startups',
    icon: 'book',
    link: '/admin/startups',
  },
  {
    label: 'Cursos',
    icon: 'paper-airplane',
    link: '/admin/courses',
  },
  {
    label: 'Startup Start',
    icon: 'paper-airplane',
    link: '/admin/startup-start',
  },
  {
    label: 'Desafios',
    icon: 'trophy',
    link: '/admin/challenges',
  },
  {
    label: 'Usuários Administrativos',
    icon: 'profile',
    link: '/admin/admins',
  },
  {
    label: 'Orientadores',
    icon: 'profile',
    link: '/admin/advisors',
  },
  {
    label: 'Mentoria',
    icon: 'profile',
    link: '/admin/mentorships/orienteds',
  },
];

const Menu: React.FC = () => {
  return (
    <S.Container>
      <S.Logo as={BrandSVG} />

      <S.Options>
        {menus.map((menu) => (
          <S.Option to={menu.link} key={menu.link}>
            <S.OptionIcon as={Icon} name={menu.icon} color="black" />

            <S.OptionLabel>{menu.label}</S.OptionLabel>
          </S.Option>
        ))}
      </S.Options>

      <S.Footer>
        <S.Options>
          <S.Option to="/logout" key="logout">
            <S.OptionLabel>Logout</S.OptionLabel>
          </S.Option>
        </S.Options>
      </S.Footer>
    </S.Container>
  );
};

export default Menu;
