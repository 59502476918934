import React from 'react';
import { Routes, Route } from 'react-router-dom';

import * as Pages from './pages';

const Courses: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.Classes />} />
      <Route path="/:class" element={<Pages.Class />} />
    </Routes>
  );
};

export default Courses;
