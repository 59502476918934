import React from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'components';
import { useService } from 'hooks';
import { dateToString } from 'utils/date';

import NewComment from '../NewComment';
import * as S from './styled';

const Comments: React.FC = () => {
  const params = useParams();
  const [showNewComment, setNewComment] = React.useState(false);

  const listService = useService('get', `mentorship/${params.mentorship}/comment`, {}, true);

  const handleOnCloseCommentForm = (refresh?: boolean) => {
    if (refresh) listService.fetch();

    setNewComment(false);
  };

  const data = listService?.data?.data?.comments?.items || [];
  const avatar =
    'https://w7.pngwing.com/pngs/1008/377/png-transparent-computer-icons-avatar-user-profile-avatar-heroes-black-hair-computer.png';

  return (
    <S.Container>
      <S.Header>
        <S.Title>Comentários sobre as últimas interações</S.Title>

        <S.NewComment onClick={() => setNewComment(true)}>Novo comentário</S.NewComment>
      </S.Header>

      <Loader show={listService.loading}>
        <S.Content>
          {data.length ? null : <S.Empty>Nenhum comentário</S.Empty>}

          {data?.map((item) => (
            <S.Comment>
              <S.CommentHeader>
                <S.UserLogo src={avatar} />

                <S.UserName>{item?.advisor?.name}</S.UserName>

                <S.Date>{dateToString(item.createdAt)}</S.Date>

                {/* <S.Reply>Responder</S.Reply> */}
              </S.CommentHeader>

              <S.Message>
                <pre>{item.description}</pre>
              </S.Message>
            </S.Comment>
          ))}
        </S.Content>
      </Loader>

      {!showNewComment ? null : <NewComment onClose={handleOnCloseCommentForm} />}
    </S.Container>
  );
};

export default Comments;
