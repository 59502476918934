import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Modal, FormGrid, FormConfig, TextField, FileField, Button } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';

interface NewCommentProps {
  onClose: (refresh?: boolean) => void;
}

const validations = {
  description: [validate.isEmpty()],
  file: [validate.isEmpty(), validate.hasSize(20)],
};

const NewComment: React.FC<NewCommentProps> = ({ onClose }) => {
  const params = useParams();
  const [loading, setLoading] = React.useState(false);
  const [form, onChange] = useForm({ validations });

  const createService = useService('post', `mentorship/${params.mentorship}/upload`, {}, false);

  const handleOnSubmit = async () => {
    setLoading(true);
    const file = form.getValue('file') as File;

    const data = {
      description: form.getValue('description'),
      file: {
        name: file.name,
        contentType: file.type,
      },
    };

    const response = await createService.fetch(data);

    if (response?.data?.status === 'OK') {
      await axios.put(response?.data?.create?.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      toast('Arquivo salvo com sucesso');
      setLoading(false);
      return onClose(true);
    }

    setLoading(false);

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'description',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Descrição',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'file',
        size: { md: 12 },
        type: FileField,
        props: (schema) => ({
          label: 'Arquivo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
  ];

  console.log('pqp', { form });

  return (
    <Modal
      size={{ md: 6 }}
      title="Novo upload"
      Footer={
        <>
          <Button variant="outlined" onClick={onClose}>
            Voltar
          </Button>

          <Button onClick={form.trySave(handleOnSubmit)} loading={loading}>
            Salvar
          </Button>
        </>
      }>
      <FormGrid config={formConfig} />
    </Modal>
  );
};

export default NewComment;
