import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TextField, Button, FormGrid, FormConfig, Space, Icon } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'utils/validate';
import { AuthContext } from 'contexts';

import * as S from './styled';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ token: string }>();

  const { setAuth } = AuthContext.useAuth();

  const changePassword = useService('post', '/auth/change-password', {}, false);

  const [form, onChange] = useForm({
    validations: {
      currentPassword: [validate.isEmpty()],
      newPassword: [validate.isEmpty()],
      rePassword: [validate.isEmpty(), validate.isEqual('newPassword', 'As senhas não coincidem')],
    },
  });

  const handleOnRemember = async () => {
    const data = {
      currentPassword: form.getValue('currentPassword'),
      newPassword: form.getValue('newPassword'),
      token: params.token,
    };
    const result = await changePassword.fetch(data);

    if (result?.data?.status === 'OK') {
      setAuth({ token: result.data.token, isLogged: true });

      const LINKS = {
        STARTUP: '/startup',
        ADMIN: '/admin',
        ADVISOR: '/advisor',
      };

      return navigate(LINKS[result.data.type as keyof typeof LINKS]);
    }

    if (result.data.status === 'ERROR') {
      form.setErrors(result.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao alterar a senha', { type: 'error' });
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'currentPassword',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Senha atual',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
          isRequired: true,
        }),
      },
      {
        schema: 'newPassword',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Nova senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
          isRequired: true,
        }),
      },
      {
        schema: 'rePassword',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Confirme a senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
          isRequired: true,
        }),
      },
    ],
  ];
  return (
    <>
      <S.IconContent>
        <Icon name="padlock" color="white" size={100} />
      </S.IconContent>

      <S.Container>
        <FormGrid config={formConfig} />

        <Space top={16} />
        <Button
          variant="gradient"
          color="white"
          width="100%"
          onClick={form.trySave(handleOnRemember)}
          loading={changePassword.loading}>
          Alterar Senha
        </Button>

        <Space top={56} />
        <Button variant="transparent" color="white" width="100%" to="/">
          Voltar
        </Button>
      </S.Container>
    </>
  );
};

export default Register;
