import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as BrandSVG } from 'assets/images/color-icon.svg';
import { Icon, IconEnum } from 'components';

import * as S from './styled';

const menus: Array<{
  label: string;
  icon: IconEnum;
  link: string;
}> = [
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/advisor/dashboard',
  },
  {
    label: 'Integrações',
    icon: 'gear',
    link: '/advisor/integrations',
  },
  // {
  //   label: 'Startups',
  //   icon: 'people',
  //   link: '/advisor/startups',
  // },
  // {
  //   label: 'Feedbacks',
  //   icon: 'grid',
  //   link: '/advisor/feedbacks',
  // },
  // {
  //   label: 'Agendamentos',
  //   icon: 'calendar',
  //   link: '/advisor/schedules',
  // },
];

const Menu: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        <S.Logo as={BrandSVG} />
      </S.Header>

      <S.Options>
        {menus.map((menu) => (
          <NavLink to={menu.link} key={menu.link}>
            {({ isActive }) => (
              <S.Option isActive={isActive}>
                <S.OptionIcon as={Icon} name={menu.icon} color="black" />

                <S.OptionLabel>{menu.label}</S.OptionLabel>
              </S.Option>
            )}
          </NavLink>
        ))}
      </S.Options>

      <S.Footer>
        <S.Options>
          <NavLink to="/logout" key="logout">
            <S.Option>
              <S.OptionLabel>Logout</S.OptionLabel>
            </S.Option>
          </NavLink>
        </S.Options>
      </S.Footer>
    </S.Container>
  );
};

export default Menu;
