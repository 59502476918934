import React from 'react';
import { useNavigate } from 'react-router-dom';

import LogoPNG from 'assets/images/white-logo.png';
import { TextField, Button, FormGrid, FormConfig, Space } from 'components';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';
import validate from 'utils/validate';

import * as S from './styled';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setAuth } = AuthContext.useAuth();

  const LoginService = useService('post', 'auth/login', {}, false);

  const [form, onChange] = useForm({
    validations: {
      email: [validate.isEmpty(), validate.isEmail()],
      password: [validate.isEmpty()],
    },
  });

  const handleOnLogin = async () => {
    const data = {
      email: form.getValue('email'),
      password: form.getValue('password'),
    };

    const result = await LoginService.fetch(data);

    if (result?.data?.status === 'ERROR') {
      form.setErrors(result.data.errors);
      return;
    }

    if (result?.data?.status === 'OK') {
      setAuth({ token: result.data.token, isLogged: true });

      const LINKS = {
        STARTUP: '/startup',
        ADMIN: '/admin',
        ADVISOR: '/advisor',
      };

      return navigate(LINKS[result.data.type as keyof typeof LINKS]);
    }

    if (result?.data?.status === 'CHANGE_PASSWORD') {
      return navigate(`/change-password/${result.data.token}`);
    }
  };

  const formConfig: FormConfig = [
    [
      {
        schema: 'email',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: ' E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],
    [
      {
        schema: 'password',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Senha',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          type: 'password',
          isRequired: true,
          mode: 'dark',
        }),
      },
    ],
  ];

  return (
    <>
      <S.Logo src={LogoPNG} alt="Logo" />

      <S.Container>
        <S.Content>
          <FormGrid config={formConfig} />

          <Space top={16} />
          <Button width="100%" onClick={form.trySave(handleOnLogin)} loading={LoginService.loading}>
            Entrar
          </Button>
        </S.Content>

        <Space top={24} />
        <Button variant="transparent" color="white" width="100%" to="/startup/register">
          Criar minha conta
        </Button>

        <Space top={24} />
        <Button variant="transparent" color="white" width="100%" to="/forgot-password">
          Esqueci minha senha
        </Button>
      </S.Container>
    </>
  );
};

export default Login;
