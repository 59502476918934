import React from 'react';
import { useState } from 'hooks';

import Modal from '../Modal';
import BoxInput, { BoxInputProps } from '../BoxInput';
import * as S from './styled';

interface TermFieldProps extends Omit<BoxInputProps, 'label'> {
  prefix: string;
  label: string;
  url: string;
}

const TermField: React.FC<TermFieldProps> = ({ prefix, label, url, ...props }) => {
  const [showModal, setModal] = useState(false);

  return (
    <>
      <BoxInput
        label={
          <>
            {prefix} <S.Link onClick={() => setModal(true)}>{label}</S.Link>
          </>
        }
        {...props}
      />

      <Modal size={{ md: 10, xs: 10 }} title="Politica de privacidade" onClose={() => setModal(false)} show={showModal}>
        <S.IFrame src={url} />
      </Modal>
    </>
  );
};

export default TermField;
