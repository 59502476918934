import React from 'react';

import Body, { BodyProps } from '../Body';
import * as S from './styled';

interface TabsProps extends Omit<BodyProps, 'children'> {
  config: Array<{
    schema: string;
    label: string;
    node: React.FC;
  }>;
  current?: string;
  onChange?: (schema: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ current, config, onChange, ...props }) => {
  const [currentTab, setTab] = React.useState<string>();

  const handleOnClick = (schema: string) => {
    onChange?.(schema);
    setTab(schema);
  };

  React.useEffect(() => {
    setTab(current || config?.[0]?.schema);
  }, [config, current]);

  const Component = React.useMemo(() => {
    if (!currentTab) return null;

    return config.find((item) => item.schema === currentTab)?.node || null;
  }, [currentTab]);

  return (
    <S.Container>
      <S.Tabs>
        {config.map((item) => (
          <S.Tab key={item.schema} onClick={() => handleOnClick(item.schema)} isActive={currentTab === item.schema}>
            {item.label}
          </S.Tab>
        ))}
      </S.Tabs>

      <Body {...props}>{Component ? <Component /> : null}</Body>
    </S.Container>
  );
};

export default Tabs;
