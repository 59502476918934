import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: rgb(228, 136, 252);
  background: linear-gradient(45deg, rgba(228, 136, 252, 1) 0%, rgba(56, 173, 253, 0.7) 50%, rgba(53, 56, 251, 1) 100%);
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 484px;
  min-height: 400px;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        width: 100%;
      `,
      1
    )};
`;
