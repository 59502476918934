import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  padding: 24px;
  padding-right: 48px;

  ${({ theme }) =>
    theme.screens.xs(
      css`
        display: none;
      `,
      1
    )};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const HeaderButtonUpdates = styled.span`
  display: flex;
  flex: 1;
  height: 44px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.colors.purple.main}33;
  color: ${({ theme }) => theme.palette.colors.purple.main};
  font-size: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-left: 12px;
  }
`;
