import styled, { DefaultTheme } from 'styled-components';

const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
  },
});

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 12px 0;
`;

export const Box = styled.div<{ value?: boolean }>`
  display: flex;
  height: 18px;
  width: 18px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 3px;
  background: #fff;
  align-items: center;
  justify-content: center;

  &:after {
    display: ${({ value }) => (value ? 'block' : 'none')};
    content: ' ';
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 2px;
  }
`;

export const Label = styled.span.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 14px;
  color: ${({ mode, modes }) => modes[mode].label.color};
  margin-left: 18px;
`;
