import React from 'react';
import { toast } from 'react-toastify';

import { Header, Loader, FormGrid, FormConfig, TextField, Button, Footer } from 'components';
import { useForm, useService } from 'hooks';
import { AuthContext } from 'contexts';

import * as S from './styled';

interface IntegrationsProps {
  type: 'STARTUP' | 'ADVISOR';
}

const validations = {};

const Integrations: React.FC<IntegrationsProps> = ({ type }) => {
  const { state: authState } = AuthContext.useAuth();

  const [form, onChange] = useForm({ validations });

  const ROUTES = {
    ADVISOR: `advisor/${authState.user?.id}/integrations`,
    STARTUP: `startup/${authState.user?.id}/integrations`,
  };

  const integrationsService = useService('get', ROUTES[type]);
  const updateService = useService('patch', ROUTES[type], null, false);

  const handleOnSubmit = async () => {
    const data: any = {
      calendly: form.getValue('calendly'),
    };

    const response = await updateService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Salvo com sucesso');
      return;
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  React.useEffect(() => {
    const data = integrationsService?.data?.data;

    if (data?.status !== 'OK') return;

    form.setValues(data?.integrations || {});
  }, [integrationsService.data]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'calendly',
        size: {
          md: 12,
        },
        type: TextField,
        props: (schema) => ({
          label: 'Token calendly',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],
  ];

  return (
    <>
      <Header title="Integrações" />

      <S.Container>
        <S.Content>
          <Loader show={integrationsService.loading}>
            <FormGrid config={formConfig} />

            <Footer>
              <Button loading={updateService.loading} onClick={form.trySave(handleOnSubmit)}>
                Salvar
              </Button>
            </Footer>
          </Loader>
        </S.Content>
      </S.Container>
    </>
  );
};

export default Integrations;
