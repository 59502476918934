import React from 'react';

import { ColorsEnum, PaletteColorsEnum } from 'types/styled-components/enuns';

import icons from './icons';
import * as S from './styled';

export type IconEnum = keyof typeof icons;

interface IconProps {
  color?: ColorsEnum | PaletteColorsEnum;
  size?: number;
  name: IconEnum;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Icon: React.FC<IconProps> = ({ color = 'primary', size = 18, name, ...props }) => {
  const Component = React.useMemo(() => icons?.[name] || icons.rocket, [name]);

  return (
    <S.Container size={size} {...props}>
      <S.Icon as={Component} color={color} width="100%" height="100%" />
    </S.Container>
  );
};

export default Icon;
