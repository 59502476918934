import React from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as ImageSVG } from 'assets/images/logo_grupo_safira.svg';
import { Body, Header, Loader } from 'components';
import { useService, useState } from 'hooks';
import { dateToString } from 'utils/date';

import ChallengeDetails from './components/ChallengeDetails';
import * as S from './styled';

const Matchmaking: React.FC = () => {
  const [selectedChallenge, setChallenge] = useState<{ link: string } | undefined>();
  const challengesService = useService('get', '/challenge/availables');
  const myChallengesService = useService('get', '/startup/my-startup/challenge');
  const registerService = useService('post', '/startup/my-startup/challenge', {}, false);

  const challenges = challengesService?.data?.data?.challenges.items || [];
  const myChallenges = myChallengesService?.data?.data?.challenges.items || [];

  const handleOnRegister = async (e: React.MouseEvent<HTMLDivElement>, item, hasChallenge) => {
    e.preventDefault();
    e.stopPropagation();

    if (hasChallenge) return;

    const data = {
      challenge: item._id,
    };

    const response = await registerService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Inscrito com sucesso');
      await myChallengesService.fetch();
      return;
    }

    return toast('Houve um erro ao inscrever', { type: 'error' });
  };

  return (
    <div>
      <Header title="Matchmaking" />

      <Body>
        <Loader show={challengesService.loading || myChallengesService.loading}>
          <S.Challenges>
            {challenges
              ?.sort((a, b) => (a.endDate - b.endDate ? 1 : -1))
              .map((item) => {
                const hasChallenge = myChallenges.find((myChallenge) => myChallenge.challenge._id === item._id);

                return (
                  <S.Challenge key={item._id}>
                    <S.ChallengeContent>
                      <S.Image as={ImageSVG} />

                      <S.Text>Desafio</S.Text>

                      <S.Title>{item.name} </S.Title>

                      <S.Text>{dateToString(item.endDate)}</S.Text>

                      <S.KnowMore onClick={() => (!item.link ? null : setChallenge(item))}>saiba mais</S.KnowMore>

                      <S.Register disabled={hasChallenge} onClick={(e) => handleOnRegister(e, item, hasChallenge)}>
                        <S.RegisterLabel>{hasChallenge ? 'Inscrito' : 'Inscreva-se'}</S.RegisterLabel>
                      </S.Register>
                    </S.ChallengeContent>
                  </S.Challenge>
                );
              })}
          </S.Challenges>
        </Loader>
      </Body>

      <ChallengeDetails challenge={selectedChallenge} onClose={() => setChallenge(() => {})} />
    </div>
  );
};

export default Matchmaking;
