import styled, { DefaultTheme } from 'styled-components';

const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
    input: {
      color: '#777',
      border: theme.palette.primary.main,
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
    input: {
      color: '#666666',
      border: theme.palette.primary.main,
    },
  },
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ mode, modes }) => modes[mode].label.color};
`;

export const InputContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Input = styled.input.attrs(({ theme }) => ({ modes: getMode(theme) }))<{
  disabled: boolean;
  mode: 'light' | 'dark';
}>`
  height: 48px;
  width: 100%;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid ${({ theme, modes, mode }) => modes[mode].input.border};
  color: ${({ mode, modes }) => modes[mode].input.color};
  background: ${({ disabled }) => (disabled ? '#ddd' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  outline: none;
  transition: ease 0.3s;

  &:focus {
    box-shadow: 0px 0px 2px ${({ modes, mode }) => modes[mode].input.border};
  }
`;

export const Legend = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  margin-bottom: 4px;
`;

export const Tooltip = styled.div`
  position: absolute;
  right: 8px;
`;

export const TooltipIcon = styled.span``;
