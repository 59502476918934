import React from 'react';
import { useParams } from 'react-router-dom';

import { useService } from 'hooks';
import { AuthContext } from 'contexts';
import { Loader, Button } from 'components';

import * as S from './styled';

interface StartupDataProps {
  OnAddFeedback: () => void;
}

const StartupData: React.FC<StartupDataProps> = ({ OnAddFeedback }) => {
  const params = useParams();
  const { state: authState } = AuthContext.useAuth();

  const startupService = useService('get', `mentorship/${params.mentorship}`);

  const logo = 'https://cdn1.iconfinder.com/data/icons/developer-set-2/512/users-512.png';

  const data = startupService?.data?.data?.mentorship;

  return (
    <Loader show={startupService.loading}>
      <S.Container>
        <S.Header>
          <S.Logo src={logo} />

          <S.Title>{data?.startup?.name}</S.Title>
        </S.Header>

        <S.Content>
          <S.Data>
            <S.Row>
              <S.Label>Data da fundação</S.Label>
              <S.Value>{data?.startup?.mentorshipData?.launchDate}</S.Value>
            </S.Row>

            <S.Row>
              <S.Label>Funcionários</S.Label>
              <S.Value>{data?.startup?.mentorshipData?.employees}</S.Value>
            </S.Row>

            <S.Row>
              <S.Label>Capital</S.Label>
              <S.Value>{data?.startup?.mentorshipData?.ownership}</S.Value>
            </S.Row>

            <S.Row>
              <S.Label>Valor de mercado</S.Label>
              <S.Value>{data?.startup?.mentorshipData?.marketCap}</S.Value>
            </S.Row>

            <S.Row>
              <S.Label>Dívida liquida</S.Label>
              <S.Value>{data?.startup?.mentorshipData?.netDebt}</S.Value>
            </S.Row>

            <S.Row>
              <S.Label>Avaliação da empresa</S.Label>
              <S.Value>{data?.startup?.mentorshipData?.firmValuation}</S.Value>
            </S.Row>

            <S.Row top={12}>
              <S.Value>{data?.startup?.mentorshipData?.address}</S.Value>
            </S.Row>

            <S.Row>
              <S.Value>{data?.startup?.mentorshipData?.website}</S.Value>
            </S.Row>
          </S.Data>

          <S.Other>
            {!(authState.user?.type === 'ADVISOR' && authState.user.id === data?.advisor?._id) ? null : (
              <Button variant="outlined" onClick={OnAddFeedback}>
                Adicionar Pendência
              </Button>
            )}
          </S.Other>
        </S.Content>
      </S.Container>
    </Loader>
  );
};

export default StartupData;
