import styled, { DefaultTheme } from 'styled-components';

const getMode = (theme: DefaultTheme) => ({
  light: {
    label: {
      color: '#fff',
    },
    input: {
      background: '#fff',
      border: '#fff',
      activeBall: theme.palette.primary.main,
      inactiveBall: theme.palette.primary.text,
    },
  },
  dark: {
    label: {
      color: '#666666',
    },
    input: {
      background: theme.palette.primary.main,
      border: theme.palette.primary.main,
      activeBall: theme.palette.primary.text,
      inactiveBall: theme.palette.primary.main,
    },
  },
});

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
`;

export const Switch = styled.span.attrs(({ theme }) => ({ modes: getMode(theme) }))<{
  mode: 'light' | 'dark';
  value: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 50px;
  height: max-content;
  border: 1px solid ${({ modes, mode }) => modes[mode].input.border};
  background: ${({ value, modes, mode }) => (value ? modes[mode].input.background : 'transparent')};
  border-radius: 32px;
  padding: 4px 4px;
  transition: ease 300ms;
`;

export const Ball = styled.span.attrs(({ theme }) => ({ modes: getMode(theme) }))<{
  mode: 'light' | 'dark';
  value: boolean;
}>`
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: ${({ value, modes, mode }) => (value ? modes[mode].input.activeBall : modes[mode].input.inactiveBall)};
  margin-left: ${({ value }) => (value ? 24 : 0)}px;
  transition: ease 300ms;
`;

export const Label = styled.div.attrs(({ theme }) => ({ modes: getMode(theme) }))<{ mode: 'light' | 'dark' }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  margin-left: 12px;
  color: ${({ mode, modes }) => modes[mode].label.color};
`;
