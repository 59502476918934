import React from 'react';
import { toast } from 'react-toastify';

import { useForm, useService } from 'hooks';
import { FormGrid, FormConfig, TextField, SelectField, Button } from 'components';
import { dateToString, stringToDate } from 'utils/date';
import validate from 'utils/validate';

import Modal from '../Modal';
import * as S from './styled';

interface NewScheduleProps {
  onClose: (refresh?: boolean) => void;
  date: Date;
  type: 'STARTUP' | 'ADVISOR';
  startup?: string;
  advisor?: string;
}

const validations = {
  title: [validate.isEmpty()],
  date: [validate.isEmpty(), validate.isDate()],
  hour: [validate.isEmptySelect()],
  advisor: validate.validateIf((form) => form.type === 'STARTUP', [validate.isEmptySelect()]),
  startup: validate.validateIf((form) => form.type === 'ADVISOR', [validate.isEmptySelect()]),
};

const NewSchedule: React.FC<NewScheduleProps> = ({ type, date, startup, advisor, onClose }) => {
  const [form, onChange] = useForm({ validations, initialValues: { type, date: dateToString(date) } });

  const availableTimesService = useService('get', '/schedule/available-times', undefined, false);
  const startupService = useService('get', '/mentorship', { advisor }, type === 'ADVISOR');
  const advisorService = useService('get', '/mentorship', { startup }, type === 'STARTUP');
  const createService = useService('post', '/schedule', {}, false);

  const handleOnSubmit = async () => {
    const data = {
      title: form.values.title,
      scheduledAt: stringToDate(`${form.values.date} ${form.values.hour.value}`, 'dd/MM/yyyy HH:mm'),
      startup: type === 'STARTUP' ? startup : form.values.startup.value,
      advisor: type === 'ADVISOR' ? advisor : form.values.advisor.value,
    };

    const response = await createService.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Agendamento salvo com sucesso');
      return onClose(true);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const startupOptions = React.useMemo(() => {
    return startupService?.data?.data?.mentorships?.items.map((item) => ({
      value: item.startup._id,
      label: item.startup.name,
    }));
  }, [startupService.data]);

  const advisorOptions = React.useMemo(() => {
    return advisorService?.data?.data?.mentorships?.items.map((item) => ({
      value: item.advisor._id,
      label: item.advisor.name,
    }));
  }, [advisorService.data]);

  React.useEffect(() => {
    const advisorId = form.getValue('advisor')?.value || advisor;
    const startupId = form.getValue('startup')?.value || startup;
    const date = form.getValue('date') || '';
    const hour = form.getValue('hour') || '';

    // se não tiver valor (quando abre o modal)
    if (!advisorId || !startupId || !date ) return;

    // se tiver algum erro;
    if (form?.errors?.startup || form?.errors?.advisor || form?.errors?.date ) return;

    // se já tiver valor preenchido
    if (hour) return;

    availableTimesService.fetch({
      startup: startupId,
      advisor: advisorId,
      date: stringToDate(date),
    } as any);
  }, [form.values]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'title',
        size: {
          md: 12,
        },
        type: TextField,
        props: (schema) => ({
          label: 'Titulo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          mode: 'dark',
        }),
      },
    ],

    [
      {
        schema: 'startup',
        size: {
          md: 12,
        },
        hide: () => type === 'STARTUP',
        type: SelectField,
        props: (schema) => ({
          label: 'Startup',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema, ['hour']),
          mode: 'dark',
          options: startupOptions,
          loading: startupService.loading,
        }),
      },
      {
        schema: 'advisor',
        size: {
          md: 12,
        },
        hide: () => type === 'ADVISOR',
        type: SelectField,
        props: (schema) => ({
          label: 'Orientador',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema, ['hour']),
          mode: 'dark',
          options: advisorOptions,
          loading: advisorService.loading,
        }),
      },
    ],

    [
      {
        schema: 'date',
        size: {
          md: 6,
        },
        type: TextField,
        props: (schema) => ({
          label: 'Data',
          mask: 'date',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema, ['hour']),
          mode: 'dark',
        }),
      },
      {
        schema: 'hour',
        size: {
          md: 6,
        },
        type: SelectField,
        props: (schema) => ({
          label: 'Hora',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          options: availableTimesService?.data?.data?.times?.map((hour) => ({ value: hour, label: hour })),
          loading: availableTimesService.loading,
          disabled:
            availableTimesService.loading || form?.errors?.startup || form?.errors?.advisor || form?.errors?.date,
          mode: 'dark',
        }),
      },
    ],
  ];

  return (
    <Modal
      show
      title="Novo agendamento"
      size={{ md: 6 }}
      noScroll
      Footer={
        <>
          <Button variant="outlined" onClick={onClose}>
            Voltar
          </Button>

          <Button onClick={form.trySave(handleOnSubmit)} loading={createService.loading}>
            Salvar
          </Button>
        </>
      }>
      <S.Content>
        <FormGrid config={formConfig} />
      </S.Content>
    </Modal>
  );
};

export default NewSchedule;
