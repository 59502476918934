import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  overflow: auto;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  padding: 8px 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #eee;
  color: #666;
  z-index: 1;
  cursor: pointer;

  ${({ isActive }) =>
    !isActive
      ? ''
      : css`
          background: #fff;
          color: #111;
          z-index: 2;
        `}
`;
