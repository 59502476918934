import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: #edf1f6;
  padding: 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Logo = styled.img`
  height: 62px;
  width: 62px;
  object-fit: cover;
`;

export const Title = styled.span`
  font-size: 32px;
  margin-left: 24px;
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 20px;
  padding-left: 48px;
`;

export const Row = styled.div<{ top?: number }>`
  display: flex;
  flex-direction: row;

  margin-top: ${({ top }) => top || 4}px;
`;

export const Label = styled.span`
  font-size: 12px;
  color: #999;

  &:after {
    content: ':';
    margin-right: 4px;
  }
`;

export const Value = styled.span`
  font-size: 12px;
`;

export const Other = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
