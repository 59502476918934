import React from 'react';
import { Outlet } from 'react-router-dom';

import { StartupUpdateData, StartupUpdateGeneralInfo } from 'components';
import { AuthContext } from 'contexts';

import Menu from './components/Menu';
import Profile from './components/Profile';
import * as S from './styled';

const Platform: React.FC = () => {
  const { state } = AuthContext.useAuth();

  const ModalPendency = React.useMemo(() => {
    if (state.startup?.needUpdateData) return StartupUpdateData;
    if (state.startup?.needUpdateGeneralInfo) return StartupUpdateGeneralInfo;

    return null;
  }, [state.startup]);

  return (
    <S.Container>
      <Menu />

      <S.Content>
        <S.Body>
          <Outlet />
        </S.Body>

        <Profile />
      </S.Content>

      {ModalPendency ? <ModalPendency /> : null}
    </S.Container>
  );
};

export default Platform;
