import React from 'react';

import { Icon } from 'components';

// import Courses from '../Courses';
import * as S from './styled';

const Profile: React.FC = () => {
  return (
    <S.Container>
      <S.Header>
        {/* <Icon name="bell" color="black" /> */}

        {/* <S.HeaderButtonUpdates>
          <Icon name="gift" color="purple" />

          <span>2 NEW UPDATES</span>
        </S.HeaderButtonUpdates> */}

        <Icon name="profile" color="white" />
      </S.Header>

      {/* <Courses /> */}
    </S.Container>
  );
};

export default Profile;
