import React from 'react';

import * as S from './styled';

interface HeaderProps {
  title: string;
  left?: any;
  right?: any;
  noMargin?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, left, right, noMargin }) => {
  return (
    <S.Container noMargin={noMargin}>
      <S.Buttons align="flex-start">{left}</S.Buttons>

      <S.Title>{title}</S.Title>

      <S.Buttons align="flex-end">{right}</S.Buttons>
    </S.Container>
  );
};

export default Header;
